import React from 'react'
import { connectNetwork } from 'lib/NetworkProvider'
import 'moment/locale/it'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { baseViewDispatch, Default, initializePage, Mobile } from '../lib/util'
import View from 'lib/View'
import Iframe from 'react-iframe'
import { Machine } from 'components'
import Material from 'printing/Material'
import WorkOrder from 'printing/WorkOrder'
import { Button, Table } from 'semantic-ui-react'

/**
 * schermata “carica filo madre” (su macchina)
 * CTA -> CARICA FILO MADRE
 * beep macchina -> a riconoscimento macchina:
 * chiedo articolo filo madre (focus campo testo, se scansiona leggo articolo, altrimenti lo metto a mano)
 * chiedo lotto (focus campo testo, se scansiona leggo articolo, altrimenti lo metto a mano)
 * CONFERMO
 * L’evento crea un’associazione permanente tra macchina filo madre e lotto fino a nuovo evento
 **/
function CaricaFiloMadre({ isEmployee, toolbarResult, filtersResult, sortsResult, network, machines, materials, workOrders, search, searchResult }) {
    const [machine, setMachine] = React.useState(null)
    const [material, setMaterial] = React.useState(null)
    const [workOrder, setWorkOrder] = React.useState(null)
    const [scannedLot, setScannedLot] = React.useState(null)

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])

        network.getMachineList('', !!isEmployee, {}, {}, -1, 10)
        network.getMaterialList('', !!isEmployee, {}, {}, -1, 10)
        network.getWorkOrderList('', {}, {}, false, false)
    }, [])

    useEffect(() => {
        if (search.startsWith('MA') && machines && machines.length > 0) {
            for (const machine of machines) {
                if (machine.machineCode === search.substring(2)) {
                    setMachine(machine)
                    searchResult('')
                    break
                }
            }
        } else if (search.startsWith('MP') && materials && materials.length > 0) {
            for (const material of materials) {
                if (material.materialId === parseInt(search.substring(2), 10)) {
                    setMaterial(material)
                    searchResult('')
                    break
                }
            }
        } else if (search.startsWith('OP')) {
            let code = search
            if (search.startsWith('OP')) {
                code = search.substring(2)
            }
            if (workOrders && workOrders.length > 0) {
                for (const workOrder of workOrders) {
                    if (workOrder.workOrderId === parseInt(code, 10)) {
                        setWorkOrder(workOrder)
                        searchResult('')
                        break
                    }
                }
            }
        } else if (search.length > 0) {
            /* eslint-disable no-console */
            console.log('SETTING LOT TO SEARCH VALUE', search)
            setScannedLot(search)
            searchResult('')
        }
    }, [search, machines, materials])

    console.log('Lot is', scannedLot, 'and search is', search)

    return (
        <View fullw fullh column style={{ paddingLeft: 46, paddingRight: 20 }}>
            <Default>
                {!machine && <div>Scansionare macchina</div>}
                {!material && <div>Scansionare materiale</div>}
                {!scannedLot && <div>Scansionare LOTTO MATERIA PRIMA</div>}
                {scannedLot && <div>Lotto: {scannedLot}</div>}
                {machine && <Machine key={`machine-${machine.machineId}`} type="full" noActions machine={machine} />}
                {material && <Material key={`material-${material.materialId}`} type="full" noActions material={material} />}
                <Table>
                    <WorkOrder type="header" />
                    {workOrder && <WorkOrder key={`workorder-${workOrder.workOrderId}`} type="table" noActions workOrder={workOrder} />}
                </Table>
            </Default>
            <Mobile>
                {!machine && <div>Scansionare macchina</div>}
                {!material && <div>Scansionare materiale</div>}
                {!scannedLot && <div>Scansionare LOTTO MATERIA PRIMA</div>}
                {machine && (
                    <div>
                        Macchina:{machine.machineId} {machine.machineName}
                    </div>
                )}
                {material && (
                    <div>
                        Materiale: {material.materialId} {material.materialName}
                    </div>
                )}
                {workOrder && (
                    <div>
                        OdP: {workOrder.workOrderId} {workOrder.workCode} {workOrder.workOrderDescription}
                    </div>
                )}
                {scannedLot && <div>Lotto: {scannedLot}</div>}
            </Mobile>
            {!!(machine && material && scannedLot) && (
                <Button
                    color="green"
                    style={{ width: '100%', height: '18vh' }}
                    onClick={async () => {
                        alert('Creando Lotto e associandolo alla macchina lot value ' + scannedLot + '|')
                        const lotresult = await network.createLot({
                            machineId: machine.machineId,
                            materialId: material.materialId,
                            lotQuantity: workOrder ? workOrder.orderedQuantity : 24,
                            workOrderId: workOrder ? workOrder.workOrderId : null,
                            lotString: scannedLot ? scannedLot : workOrder.workOrderId,
                        })
                        await network.createMachine({ machineId: machine.machineId, activeLotId: lotresult.lotId })
                    }}
                >
                    CONFERMA MA{machine.machineId} MP{material.materialId} Lotto: {scannedLot}
                </Button>
            )}
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getWorkOrderList, getMaterialList, getMachineList, getRecentMachineList, deleteMachine, search, organization, login } = state
    let { isEmployee } = ownProps
    let machineList = getMachineList
    if (isEmployee) {
        machineList = getRecentMachineList
    }
    return {
        search: search.data,
        machines: machineList.data.data,
        fetching: machineList.fetching,
        materials: getMaterialList.data.data,
        workOrders: getWorkOrderList.data.data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(CaricaFiloMadre)))
