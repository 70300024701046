import { ColorModeScript } from '@chakra-ui/react'
import React from 'react'
import App, { theme } from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
    <React.Fragment>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <App />
    </React.Fragment>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
