import { Box } from '@chakra-ui/layout'
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Dimmer, Form, Input, Loader, Message, Placeholder, TextArea } from 'semantic-ui-react'
import DropdownSearch from 'utility/DropdownSearch'
import { baseViewDispatch, createAlert } from '../lib/util'
function RecipeEditor({ network, recipeId, onSave, onCancel, defaultDescription, error, hide, info, removeAll, show, success, warning }) {
    const [recipe, setRecipe] = useState({ recipeMachineSpecificData: {} })
    const [machine, setMachine] = useState({})
    const [status, setStatus] = useState(null)
    const [dirty, setDirty] = useState(false)

    useEffect(() => {
        if (recipeId) {
            network.getRecipe(recipeId).then((data) => {
                // console.log('Got recipe', data.data)
                const recipe = data.data
                if (recipe.machineId) {
                    setMachine({
                        machineId: recipe.machineId,
                        machineName: recipe.machineName,
                        machineType: recipe.machineType,
                    })
                }
                if (!recipe.recipeMachineSpecificData) {
                    if (recipe.machineType === 12) {
                        recipe.recipeMachineSpecificData = {
                            AbilitaCambio: 0,
                            Bobina1: 1,
                            Bobina2: 1,
                            Bobina3: 1,
                            Bobina4: 1,
                            Bobina_FT_G: 0,
                            Centraggio: 0,
                            CorreDia: 0,
                            DiaFilo: 0,
                            DiaMax: 0,
                            DistSpire: 0,
                            Larghezza: 0,
                            Metri: 0,
                            Nocciolo: 0,
                            NumFili: 0,
                            PassoSor: 0,
                            PosIniziale: 0,
                            PosLegatura: 0,
                            StartDir: 0,
                            StatusO: 0,
                            VelMtMin: 0,
                            VelRotRpm: 0,
                            VelSor: 0,
                        }
                    } else {
                        recipe.recipeMachineSpecificData = {}
                    }
                } else {
                    recipe.recipeMachineSpecificData = JSON.parse(recipe.recipeMachineSpecificData)
                }
                setRecipe(recipe)
            })
        } else {
            setRecipe({
                recipeCode: 'R001',
                recipeName: defaultDescription ? defaultDescription : '',
                recipeMachineSpecificData: {},
            })
        }
    }, [recipeId])

    const handleInput = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setRecipe({
            ...recipe,
            [name]: value,
        })
    }

    const save = () => {
        network
            .createRecipe({
                recipeId: recipe.recipeId,
                recipeIndex: recipe.recipeIndex,
                recipeName: recipe.recipeName,
                recipeCode: recipe.recipeCode,
                recipeMachine: recipe.recipeMachine,
                recipeMachineSpecificData: JSON.stringify(recipe.recipeMachineSpecificData),
            })
            .then((data) => {
                // console.log('Got recipe', data.data)
                setRecipe(data.data)
            })
            .then((result) => {
                success(createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Ok', () => {}))
                setStatus('success')
                if (onSave) {
                    onSave(result)
                }
            })
            .catch((error) => {
                error(createAlert('Errore nel tentativo di contattare il server', 'Riprova piú tardi'))
                setStatus('error')
            })
    }

    if (!recipe) {
        return (
            <Box p={6}>
                <Dimmer active>
                    <Loader />
                </Dimmer>

                <Placeholder>
                    <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Paragraph>
                </Placeholder>
            </Box>
        )
    }

    const { pricePerUnit, unit, recipeCode, recipeName, recipeMachine, machineName, recipeIndex } = recipe

    let distSpecificUI = null
    if (recipe && machine.machineType === 11) {
        const rows = []
        const header = []

        header.push(<Th>Passo</Th>)
        header.push(<Th>Temperatura finale</Th>)
        header.push(<Th>LAVORO</Th>)
        header.push(<Th>PAUSA</Th>)
        header.push(<Th>TEMPO CAMPIONAMENTO [s]</Th>)
        header.push(<Th>TEMPO TOTALE [m]</Th>)

        rows.push(<Thead>{header}</Thead>)
        for (let i = 0; i < 10; i++) {
            const row = []
            for (let j = 0; j < 5; j++) {
                if (j === 0) {
                    row.push(<Td>Passo {i + 1}</Td>)
                }

                row.push(
                    <Td>
                        <Input
                            onChange={(event) => {
                                const target = event.target
                                const value = target.type === 'checkbox' ? target.checked : target.value
                                const name = target.name

                                if (!recipe.recipeMachineSpecificData[i]) {
                                    recipe.recipeMachineSpecificData[i] = {}
                                }
                                recipe.recipeMachineSpecificData[i][j] = value

                                /* eslint-disable */
                                console.log('Specific machine data is', recipe.recipeMachineSpecificData)
                                setRecipe({
                                    ...recipe,
                                })
                            }}
                            value={
                                recipe.recipeMachineSpecificData[i] && recipe.recipeMachineSpecificData[i][j]
                                    ? recipe.recipeMachineSpecificData[i][j]
                                    : ''
                            }
                        />
                    </Td>
                )
            }
            rows.push(<Tr>{row}</Tr>)
        }

        distSpecificUI = <Table>{rows}</Table>
    }

    console.log('Recipe and machine are', recipe, machine)
    if (recipe && machine.machineType === 12 && recipe.recipeMachineSpecificData) {
        const machineSpecificChange = (event) => {
            const target = event.target
            const value = target.type === 'checkbox' ? target.checked : target.value
            const name = target.name.toLowerCase()

            recipe.recipeMachineSpecificData[name] = value

            /* eslint-disable */
            console.log('Specific machine data is', recipe.recipeMachineSpecificData)
            setRecipe({
                ...recipe,
            })
        }

        // a JSX form for the specific machine data
        distSpecificUI = (
            <>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-recipe-bobina1"
                        control={Input}
                        label={<span className="label">Bobina 1</span>}
                        name="bobina1"
                        value={recipe.recipeMachineSpecificData.bobina1}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                        type="number"
                        min="0"
                        max="1"
                    />
                    <Form.Field
                        id="form-input-control-recipe-bobina2"
                        control={Input}
                        label={<span className="label">Bobina 2</span>}
                        name="bobina2"
                        value={recipe.recipeMachineSpecificData.bobina2}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                        type="number"
                        min="0"
                        max="1"
                    />
                    <Form.Field
                        id="form-input-control-recipe-bobina3"
                        control={Input}
                        label={<span className="label">Bobina 3</span>}
                        name="bobina3"
                        value={recipe.recipeMachineSpecificData.bobina3}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                        type="number"
                        min="0"
                        max="1"
                    />
                    <Form.Field
                        id="form-input-control-recipe-bobina4"
                        control={Input}
                        label={<span className="label">Bobina 4</span>}
                        name="bobina4"
                        value={recipe.recipeMachineSpecificData.bobina4}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                        type="number"
                        min="0"
                        max="1"
                    />
                    <Form.Field
                        id="form-input-control-recipe-bobinaftg"
                        control={Input}
                        label={<span className="label">Bobina FT/G</span>}
                        name="bobinaftg"
                        value={recipe.recipeMachineSpecificData.bobinaftg}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                        type="number"
                        min="0"
                        max="1"
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-recipe-centraggio"
                        control={Input}
                        label={<span className="label">Centraggio</span>}
                        name="centraggio"
                        value={recipe.recipeMachineSpecificData.centraggio}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                    <Form.Field
                        id="form-input-control-recipe-CorreDia"
                        control={Input}
                        label={<span className="label">CorreDia</span>}
                        name="CorreDia"
                        value={recipe.recipeMachineSpecificData.corredia}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                    <Form.Field
                        id="form-input-control-recipe-DiaFilo"
                        control={Input}
                        label={<span className="label">DiaFilo</span>}
                        name="DiaFilo"
                        value={recipe.recipeMachineSpecificData.diafilo}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                    <Form.Field
                        id="form-input-control-recipe-DiaMax"
                        control={Input}
                        label={<span className="label">DiaMax</span>}
                        name="DiaMax"
                        value={recipe.recipeMachineSpecificData.diamax}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                    <Form.Field
                        id="form-input-control-recipe-DistSpire"
                        control={Input}
                        label={<span className="label">DistSpire</span>}
                        name="DistSpire"
                        value={recipe.recipeMachineSpecificData.distspire}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-recipe-Larghezza"
                        control={Input}
                        label={<span className="label">Larghezza</span>}
                        name="Larghezza"
                        value={recipe.recipeMachineSpecificData.larghezza}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                    <Form.Field
                        id="form-input-control-recipe-Nocciolo"
                        control={Input}
                        label={<span className="label">Nocciolo</span>}
                        name="Nocciolo"
                        value={recipe.recipeMachineSpecificData.nocciolo}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                    <Form.Field
                        id="form-input-control-recipe-NumFili"
                        control={Input}
                        label={<span className="label">NumFili</span>}
                        name="NumFili"
                        value={recipe.recipeMachineSpecificData.numfili}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-recipe-PassoSor"
                        control={Input}
                        label={<span className="label">PassoSor</span>}
                        name="PassoSor"
                        value={recipe.recipeMachineSpecificData.passosor}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                    <Form.Field
                        id="form-input-control-recipe-PosIniziale"
                        control={Input}
                        label={<span className="label">PosIniziale</span>}
                        name="PosIniziale"
                        value={recipe.recipeMachineSpecificData.posiniziale}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                    <Form.Field
                        id="form-input-control-recipe-PosLegatura"
                        control={Input}
                        label={<span className="label">PosLegatura</span>}
                        name="PosLegatura"
                        value={recipe.recipeMachineSpecificData.poslegatura}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                    <Form.Field
                        id="form-input-control-recipe-StartDir"
                        control={Input}
                        label={<span className="label">StartDir</span>}
                        name="StartDir"
                        value={recipe.recipeMachineSpecificData.startdir}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-recipe-VelMtMin"
                        control={Input}
                        label={<span className="label">VelMtMin</span>}
                        name="VelMtMin"
                        value={recipe.recipeMachineSpecificData.velmtmin}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                    <Form.Field
                        id="form-input-control-recipe-VelRotRpm"
                        control={Input}
                        label={<span className="label">VelRotRpm</span>}
                        name="VelRotRpm"
                        value={recipe.recipeMachineSpecificData.velrotrpm}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                    <Form.Field
                        id="form-input-control-recipe-VelSor"
                        control={Input}
                        label={<span className="label">VelSor</span>}
                        name="VelSor"
                        value={recipe.recipeMachineSpecificData.velsor}
                        onChange={(e) => machineSpecificChange(e)}
                        placeholder=""
                    />
                </Form.Group>
            </>
        )
    }

    return (
        <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
            <Form success={status === 'success'} error={status === 'error'} warning={status === 'warning'}>
                <Box p={6}>
                    <Box textStyle="header" as="h1">
                        Anagrafica ricetta
                    </Box>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-recipe-name"
                            control={TextArea}
                            label={<span className="label">Nome Ricetta</span>}
                            name="recipeName"
                            value={recipeName}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-recipe-code"
                            control={Input}
                            label={<span className="label">Codice ricetta (opzionale)</span>}
                            name="recipeCode"
                            value={recipeCode}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-recipe-code"
                            control={Input}
                            label={<span className="label">Corrispondenza ricetta Macchinario</span>}
                            name="recipeIndex"
                            value={recipeIndex}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                    <DropdownSearch
                        isMulti={false}
                        onChange={(machine) => {
                            setRecipe({
                                ...recipe,
                                recipeMachine: machine[0].machineId,
                            })

                            setMachine(machine)
                        }}
                        value={recipeMachine}
                        entity={'Machine'}
                        isSearchable={true}
                        placeholder="Seleziona Macchina di riferimento"
                        keyfield="machineId"
                        descriptionfield="machineName"
                        singleValueInitialDescription={machineName}
                    />
                </Box>

                {distSpecificUI && <Box>{distSpecificUI}</Box>}

                <Form.Group>
                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            save()
                        }}
                        color="green"
                    />
                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-cancel"
                        control={Button}
                        content="Annulla"
                        onClick={() => {
                            if (onCancel) {
                                onCancel()
                            }
                        }}
                        color="red"
                    />
                </Form.Group>
                <Message success header="Form completato con successo" content="Ricetta inserito con successo!" />
                <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
            </Form>
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { login } = state

    return {
        login,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(RecipeEditor)))
