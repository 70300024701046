import { Box, Stack } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Select, Step, TextArea } from 'semantic-ui-react'
import { Article, Job, PickFile, PickImage } from '../components'
import { baseViewDispatch, initializePage } from '../lib/util'
import View from '../lib/View'
import ArticleDropdown from '../utility/ArticleDropdown'
import CustomerDropdown from '../utility/CustomerDropdown'
import { routes, route_from } from 'lib/routes'
import { createAlert } from 'lib/util'
import { Switch, Wrap } from '@chakra-ui/react'
const { app, printjobs } = routes

const defaultJob = {
    jobCode: '#AUTO#',
    jobName: moment().format('DDMMYY'),
    jobClientCode: '',
    description: '',
    jobType: 3,
    client: 'Cliente',
    image: '',
    urgencyLevel: 0,
    createdExternal: 0,
    billed: 0,
    active: 1,
    budgeted: 0,
    jobProgram: '',
    deliveryDate: new Date(),
    deliveryWeek: moment().get('week'),
    orderDate: new Date(),
    customerId: 1,
    jobLocation: '',
    jobMachineType: 0,
}

function WorkOrderWizard(props) {
    const { network, toolbarResult, filtersResult, sortsResult, isEmployee, success, history } = props
    const [step, setStep] = useState(0)
    const [typeOptions, setTypeOptions] = useState([])
    const [job, setJob] = useState(defaultJob)
    const [articles, setArticles] = useState([{}])
    const [articleCount, setArticleCount] = useState(1)
    const [autoId, setAutoId] = useState(true)

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
        network.getJobTypeList().then((result) => {
            const types = result.data
            let options = []
            for (let i in types) {
                let type = types[i]
                if (type.jobType !== 1) {
                    options.push({
                        key: type.jobType,
                        text: type.typeName,
                        value: type.jobType,
                    })
                }
            }

            setTypeOptions(options)
        })
    }, [])

    const handleInput = (event) => {
        const target = event.target
        let value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        if (name === 'deliveryWeek') {
            if (value < 1) value = 1
            if (value > 53) value = 53
        }

        setJob({ ...job, [name]: value })

        if (name === 'deliveryWeek') {
            setJob({
                ...job,
                deliveryWeek: value,
                deliveryDate: moment().set('week', value).toDate(),
            })
        }
    }

    const articleDropdowns = []
    for (let i = 0; i < articleCount; i++) {
        articleDropdowns.push(
            <Stack direction={['column', 'row']} spacing="24px" horizontal key={`ArticleDropdownWizardSegmentGroup_${i}`}>
                <Box w={600} p={6} key={`ArticleDropdownWizardSegment_${i}`}>
                    <ArticleDropdown
                        key={`ArticleDropdownWizard_${i}`}
                        selected={articles}
                        onArticleSelected={(ev, data, selected, selectedArticle) => {
                            articles[i] = selectedArticle
                            setArticles(articles)
                        }}
                        defaultSelectedArticles={articles[i]}
                        single
                    />
                </Box>
                <Box p={6}>
                    <Button
                        key={`ArticleDropdownWizardBtnRmv_${i}`}
                        onClick={() => {
                            articles.splice(i, 1)
                            setArticles(articles)
                            setArticleCount(articleCount - 1)
                        }}
                    >
                        Rimuovi articolo
                    </Button>
                </Box>
            </Stack>
        )
    }

    return (
        <>
            <Box width={[0.99, 0.99, 0.99, 8 / 10]} m="auto" p="0">
                <Form
                    success={job.status === 'success'}
                    error={job.status === 'error'}
                    warning={job.status === 'warning'}
                    style={{ margin: 8, marginBottom: 42 }}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-job-id"
                            control={Input}
                            label={<span className="label">Codice ordine cliente interno</span>}
                            name="jobName"
                            value={job.jobName}
                            onChange={(e) => handleInput(e)}
                            placeholder="Ordine cliente interno"
                        />
                        <Form.Field
                            id="form-input-control-job-client-code"
                            control={Input}
                            label={<span className="label">Numero documento cliente</span>}
                            name="jobClientCode"
                            value={job.jobClientCode}
                            // disabled={!!id || updating}
                            onChange={(e) => handleInput(e)}
                            placeholder="Numero documento cliente"
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-job-description"
                            control={TextArea}
                            label={<span className="label">Note</span>}
                            placeholder="Note"
                            name="description"
                            value={job.description}
                            onChange={(e) => handleInput(e)}
                        />
                    </Form.Group>
                    {job.jobType !== 3 && (
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-textarea-control-job-program"
                                control={Input}
                                label={<span className="label">Programma</span>}
                                placeholder="Programma"
                                name="jobProgram"
                                value={job.jobProgram}
                                disabled
                                onChange={(e) => handleInput(e)}
                            />
                            <PickFile
                                onChange={(jobProgram) => {
                                    setJob({ ...job, jobProgram: jobProgram })
                                }}
                                value={job.jobProgram}
                            />
                        </Form.Group>
                    )}
                    {/* <Form.Group widths="equal" style={{ margin: 0 }}> */}
                </Form>

                {articleDropdowns}
                <Button
                    onClick={() => {
                        setArticleCount(articleCount + 1)
                        articles.push({})
                        setArticles(articles)
                    }}
                >
                    Aggiungi articolo
                </Button>
            </Box>
            <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                <Button
                    color="green"
                    onClick={() => {
                        if (autoId) {
                            job.jobCode = '#AUTO#'
                        }

                        const toSave = {
                            ...job,
                            deliveryDate: job.deliveryDate
                                ? moment(job.deliveryDate).add('hour', 2).format('YYYY-MM-DD')
                                : moment().format('YYYY-MM-DD'),
                        }
                        delete toSave.deliveryWeek
                        network.createJob(job.jobCode, toSave).then((result) => {
                            for (const article of articles) {
                                if (article.articleId) {
                                    network.createWorkOrder({
                                        workCode: article.articleCode,
                                        jobCode: result.jobCode,
                                        clientOrderCode: result.jobCode,
                                        product: article.articleCode,
                                        productQuantity: article.quantity,
                                        remainingQuantity: article.quantity,
                                        producedQuantity: 0,
                                        deliveredQuantity: 0,
                                        articleId: article.articleId,
                                        completed: 0,
                                        deliveryDate: moment(article.deliveryDate).format('YYYY-MM-DD'),
                                        productionDate: moment(article.productionDate).format('YYYY-MM-DD'),
                                    })
                                }
                            }
                            success(
                                createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Visualizza', () => {
                                    history.push(route_from(app, printjobs))
                                })
                            )
                        })
                        setJob(defaultJob)
                        setArticles([{}])
                        setStep(0)
                    }}
                >
                    Fine
                </Button>
            </View>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteMold, mergeMold } = state

    return {
        getMachineList,
        deleteMold,
        mergeMold,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(WorkOrderWizard)))
