import moment from 'moment-timezone'
import axios from 'axios'
let hadesApi = axios.create({})
hadesApi.defaults.headers.common['Authorization'] = `Bearer ${process.env.REACT_APP_HADES_NEGRIBOSSI_TOKEN}`

moment.tz.setDefault('Europe/Rome').locale('it')

const minmax = (num, min, max) => Math.min(Math.max(num, min), max)

function compare(a, b) {
    if (moment(a.timestamp).isAfter(b.timestamp)) return 1
    return -1
}

export const compute_oee = async (lineId, start, end) => {
    let computed = {}
    let from = start.format('YYYY-MM-DD HH:mm:ss')
    let to = end.format('YYYY-MM-DD HH:mm:ss')

    // console.log('compute', from, to)

    const filters = [
        {
            key: 'timestamp',
            symbol: '>',
            value: from,
        },
        {
            key: 'timestamp',
            symbol: '<',
            value: to,
        },
    ]
    // let codeResponse = await hadesApi
    //     .get(`/table/line_codes`, {
    //         params: {
    //             id: `${lineId}`,
    //             sub_id: 'default',
    //             filters,
    //         },
    //     })
    //     .catch((e) => console.error(e.response.data.message))

    let uniqueCodes = ['0']
    // let uniqueCodes = codes.map((x) => x.code).filter((value, index, self) => self.indexOf(value) === index)
    // console.log('codes', uniqueCodes)

    // full data per hour all codes
    let dataByH = {}

    // console.log('uniqueCodes', uniqueCodes)
    for (let code of uniqueCodes || []) {
        let fulldata = []
        // console.log('asking code', code)
        let dataResponse = await hadesApi.get(`${process.env.REACT_APP_HADES}table/line/${lineId}`, {
            params: {
                id: `${code}`,
                sub_id: '0',
                filters,
            },
        })
        let { data: pagedData } = dataResponse
        pagedData = pagedData.reverse()
        fulldata = [...pagedData]
        // console.log('dataResponse', dataResponse)

        let latestStamp = null

        while (pagedData.length === 5000) {
            let [lastTimestamp] = pagedData || []
            if (!lastTimestamp || (latestStamp && latestStamp === lastTimestamp.timestamp)) break
            let newto = moment.utc(lastTimestamp.timestamp).format('YYYY-MM-DD HH:mm:ss')
            let newfrom = moment.utc(lastTimestamp.timestamp).format('YYYY-MM-DD HH:mm:ss')
            latestStamp = lastTimestamp.timestamp

            if (moment(from).isAfter(newto)) break
            let nextpage = await hadesApi.get(`${process.env.REACT_APP_HADES}table/line/${lineId}`, {
                params: {
                    id: `${code}`,
                    sub_id: '0',
                    filters: [
                        {
                            key: 'timestamp',
                            symbol: '>',
                            value: newfrom,
                        },
                        // {
                        //     key: 'timestamp',
                        //     symbol: '<',
                        //     value: newto,
                        // },
                    ],
                },
            })
            let { data } = nextpage
            pagedData = data.reverse()
            fulldata = [...pagedData, ...fulldata]
        }

        for (let data of fulldata) {
            let baseh = moment(data.timestamp).format('H')
            if (!dataByH[baseh]) dataByH[baseh] = []
            dataByH[baseh].push(data)
        }
    }
    //order by timestamp all data in each hour
    for (let k in dataByH) {
        let data = dataByH[k]
        data.sort(compare)
        // console.log(data)
    }
    // for each hour
    for (let k in dataByH) {
        let oeeObject = {}
        let data = dataByH[k]
        // console.log('hour', k, data)
        let [firstItem] = data || []
        let prevTime = moment(firstItem.timestamp).clone().set({ minute: '0', second: '0' })
        let prevData = null
        let avgcycle = 0
        let bestcycle = null
        let maximumPossibleCycles = 0

        let cycCounter = 0
        let okCounter = 0
        let koCounter = 0

        for (let item of data) {
            let { id, timestamp, al000, jobgoodpartscounter: npzok, jobbadpartscounter: npzko, cycletime: tmpcod, machinemode, cyclecounter } = item

            let mnl = item.mnl
            let start = item.start
            let stop = item.stop

            let {
                abilitacambio_i,
                bobina1_i,
                bobina2_i,
                bobina3_i,
                bobina4_i,
                bobina_ft_g_i,
                centraggio_i,
                corredia_i,
                diafilo_i,
                diamax_i,
                distspire_i,
                larghezza_i,
                metri_i,
                nocciolo_i,
                numcicli,
                numfili_i,
                odl,
                passosor_i,
                posiniziale_i,
                poslegatura_i,
                startdir_i,
                statusopc,
                tacc_i,
                tdecqstop_i,
                tdec_i,
                velmtmin,
                velmtmin_i,
                velrotrpm_i,
                velsor_i,
                vismetri,
                vqcicli,
            } = item

            if (!prevData) {
                start = 1
                stop = 0
                mnl = 0
            } else {
                if (vismetri !== prevData.vismetri) {
                    start = 1
                    stop = 0
                    mnl = 0
                } else {
                    start = 0
                    stop = 1
                    mnl = 1
                }

                /* eslint-disable */
                console.log('Checking vismetri', vismetri, prevData.vismetri)
                if (vismetri < prevData.vismetri) {
                    cycCounter += 1
                    if (bobina1_i === 1) {
                        okCounter += 1
                    }
                    if (bobina2_i === 1) {
                        okCounter += 1
                    }
                    if (bobina3_i === 1) {
                        okCounter += 1
                    }
                    if (bobina4_i === 1) {
                        okCounter += 1
                    }
                }
            }

            if (!cyclecounter) {
                cyclecounter = cycCounter
            }

            if (!npzok) {
                npzok = okCounter
            }

            if (!npzko) {
                npzko = koCounter
            }

            console.log('COUNTERS', { cycCounter, okCounter, koCounter, start, stop, mnl })

            const cyclesecs = tmpcod / 1000
            const npztrc = 3600 / cyclesecs
            avgcycle += cyclesecs

            if (!bestcycle) {
                bestcycle = cyclesecs
            }

            if (machinemode) {
                if (machinemode === 1) {
                    start = 1
                    mnl = 0
                    stop = 0
                } else if (machinemode === 2) {
                    start = 0
                    mnl = 1
                    stop = 0
                } else {
                    start = 0
                    mnl = 0
                    stop = 1
                }
            }

            if (!oeeObject[id]) {
                oeeObject[id] = {
                    totaltime: 0,
                    uptime: 0,
                    downtime: 0,
                    downtime_al: {},
                    bad_al: {},
                    good: 0,
                    bad: 0,
                    prev_cycles: cyclecounter,
                    prev_good: npzok,
                    prev_bad: npzko,
                    tmpcod,
                    npztrc,
                    cycles: 0,
                }
            }
            // sumup good and bad + assing downtime and uptime
            let incremental_cycle = Math.max(0, cyclecounter - oeeObject[id].prev_cycles)
            let incremental_good = oeeObject[id].prev_good > npzok ? 0 : Math.max(0, npzok - oeeObject[id].prev_good)
            let incremental_bad = Math.max(0, npzko - oeeObject[id].prev_bad)
            oeeObject[id].cycles += incremental_cycle
            oeeObject[id].good += incremental_good
            oeeObject[id].bad += incremental_bad
            oeeObject[id].prev_cycles = cyclecounter
            oeeObject[id].prev_good = npzok
            oeeObject[id].prev_bad = npzko

            if (!oeeObject[id].downtime_al[al000]) oeeObject[id].downtime_al[al000] = 0
            if (!oeeObject[id].bad_al[al000]) oeeObject[id].bad_al[al000] = 0

            let currentTime = moment(timestamp).clone()
            let elapsedTime = moment(currentTime).diff(prevTime, 'seconds')
            prevTime = currentTime.clone()

            console.log('COUNTERS', { cycCounter, okCounter, koCounter, start, stop, mnl })
            console.log('Elapsed time:', elapsedTime, 'seconds', 'start:', start, 'stop:', stop, 'mnl:', mnl)
            oeeObject[id].totaltime += elapsedTime
            if (start === 1) {
                oeeObject[id].uptime += elapsedTime
            } else {
                oeeObject[id].downtime += elapsedTime
                oeeObject[id].downtime_al[al000] += elapsedTime
                oeeObject[id].bad_al[al000] += incremental_bad
            }

            prevData = item
        }

        avgcycle = avgcycle / data.length
        maximumPossibleCycles = bestcycle ? 3600 / bestcycle : 0
        let totalPossiblePieces = 0

        for (let code in oeeObject) {
            // console.log('code', code)
            let codeOeeObject = oeeObject[code]
            //Compute QR, UE, AV, OEE
            let th_npztrc = (3600 / codeOeeObject.tmpcod) * 10
            let single_pcs_time = 3600 / maximumPossibleCycles // TODO number of pieces per cycle
            totalPossiblePieces += maximumPossibleCycles
            let max_pcs_th_for_uptime = maximumPossibleCycles ? codeOeeObject.uptime / single_pcs_time : 0
            let total_done = codeOeeObject.good + codeOeeObject.bad
            let qr = 1
            if (total_done != 0) {
                qr = minmax(codeOeeObject.good / total_done, 0, 1)
            }
            let ue = 1
            if (max_pcs_th_for_uptime !== 0) {
                ue = minmax(total_done / max_pcs_th_for_uptime, 0, 1)
                // console.log('Computing RV ', total_done, '/', max_pcs_th_for_uptime, '=', ue, single_pcs_time, maximumPossibleCycles)
            }
            let av = 1
            if (codeOeeObject.uptime + codeOeeObject.downtime != 0) {
                av = minmax(codeOeeObject.uptime / (codeOeeObject.uptime + codeOeeObject.downtime), 0, 1)
            }
            let oee = minmax(qr * av * ue, 0, 1)

            //Compute loss and RF, RV, RQ
            let rv_time_loss = (max_pcs_th_for_uptime - total_done) * single_pcs_time
            let rq_time_loss = codeOeeObject.bad * single_pcs_time

            let rf = minmax(codeOeeObject.downtime / codeOeeObject.totaltime, 0, 1)
            let rv = minmax(rv_time_loss / codeOeeObject.totaltime, 0, 1)
            let rq = minmax(rq_time_loss / codeOeeObject.totaltime, 0, 1)
            // console.log('good', codeOeeObject.good)
            // console.log('bad', codeOeeObject.bad)
            // console.log('max per uptime', max_pcs_th_for_uptime)
            // console.log('uptime', codeOeeObject.uptime)
            // console.log('downtime', codeOeeObject.downtime)
            // console.log('totaltime', codeOeeObject.totaltime)

            // console.log('oee', oee)
            // console.log('quality rate', qr)
            // console.log('efficiency', ue)
            // console.log('availability', av)
            // console.log('loss for quality', rq)
            // console.log('loss for slowtime', rv)
            // console.log('loss for downtime', rf)
            // console.log('downtime per alarm', codeOeeObject.downtime_al)
            // console.log('bad pcs per alarm', codeOeeObject.bad_al)

            if (!computed[code]) computed[code] = {}
            if (!computed[code][k]) {
                computed[code][k] = {
                    good: codeOeeObject.good,
                    bad: codeOeeObject.bad,
                    npztrc: maximumPossibleCycles,
                    max_pcs_th_for_uptime: maximumPossibleCycles,
                    uptime: codeOeeObject.uptime,
                    downtime: codeOeeObject.downtime,
                    downtime_al: codeOeeObject.downtime_al,
                    bad_al: codeOeeObject.bad_al,
                    oee: !isNaN(oee) ? oee : 0,
                    rq: !isNaN(rq) ? rq : 0,
                    rv: !isNaN(rv) ? rv : 0,
                    rf: !isNaN(rf) ? rf : 0,
                    avgcycle,
                    totalPossiblePieces,
                    cycles: codeOeeObject.cycles,
                }
            }
        }
    }
    return computed
}
