export function getLot(data) {
    return new Promise((resolve, reject) => {
		let { getLotRequest, getLotError, getLotResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_lot',
			this.createBundle(
				'get_lot',
				{ lotId: data },
				this.GET,
				getLotRequest,
                (result) => {
                    getLotResult(result)
                    resolve(result)
                },
                (error) => {
                    getLotError(error)
                    reject(error)
                }
			)
		);
	})
}
