import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { SideBar, Steps } from '../components'
import { Works } from '../containers'
import { Default } from '../lib/util'

class DetailView extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidUpdate() {}

    componentDidMount() {}

    render() {
        let { workday, worker } = this.props

        return (
            <View row fullw fullh>
                <View row fullh style={{ width: 110 }}>
                    <Default>
                        <SideBar isEmployee isDateSidebar />
                    </Default>
                </View>

                <View style={styles.work_container}>
                    <Works
                        deletable
                        isEmployee
                        fullh
                        childProps={{ hideDate: true, hideWorker: true, hideDescription: 'tooltip', hideWorkerHours: true, hideMachineHours: true }}
                        style={{ width: '100%' }}
                        worker={worker.id}
                        date={workday ? workday.format('YYYY-MM-DD') : ''}
                    />
                </View>
                <View fullw fullh>
                    <Steps worker={worker.id} />
                </View>
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        workday: {
            data: { date: workday },
        },
        loginEmployee: {
            data: { user: worker },
        },
    } = state

    return {
        workday,
        worker,
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(DetailView)))

const styles = {
    work_container: {
        borderRight: '1px solid grey',
        width: '30%',
        minWidth: '30%',
        maxWidth: '30%',
    },
}
