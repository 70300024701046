import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Input, Modal, Table } from 'semantic-ui-react'
import RecipeEditor from '../job/RecipeEditor'
import { baseViewDispatch, initializePage } from '../lib/util'
import Recipe from './Recipe'
function Recipes(props) {
    const {
        searchText,
        network,
        table,
        time,
        label,
        field,
        format,
        toolbarResult,
        filtersResult,
        sortsResult,
        deleteRecipe,
        sub,
        login,
    } = props
    const [showNoteEditor, setShowNoteEditor] = useState({
        show: false,
        recipe: null,
    })

    const [recipes, setRecipes] = useState([])
    const [count, setCount] = useState(0)
    const [dirty, setDirty] = useState(false)
    const [creating, setCreating] = useState(false)
    const [editing, setEditing] = useState(false)
    const [filters, setFilters] = useState({})
    const [sorts, setSorts] = useState({})
    const [page, setPage] = useState(0)
    const pageRef = useRef()
    const countRef = useRef()

    const items = 10

    pageRef.current = page
    countRef.current = count

    useEffect(() => {
        network.getRecipeList(searchText ? searchText : '', false, filters, sorts, page, 10).then((data) => {
            // console.log(data)

            const recipes = []
            for (const key in data.data) {
                recipes.push(data.data[key])
            }
            setCount(data.count)
            setRecipes(recipes)
            setDirty(false)
        })
    }, [dirty, deleteRecipe.fetching, page, searchText])

    useEffect(() => {
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []
        if (user && !!user.manage_article) {
            extraTools.push({
                name: 'Crea nuovo ricetta',
                icon: 'add',
                action: () => {
                    setCreating(true)
                },
            })
            // extraTools.push({
            //     name: 'Importa stampi da file',
            //     icon: 'check',
            //     action: () => {
            //         this.setState({
            //             showImport: true,
            //         })
            //     },
            // })
        }

        const toolbar = sub
            ? []
            : [
                  ...extraTools,
                  {
                      name: '',
                      icon: 'arrow left',
                      position: 'right',
                      action: function () {
                          if (pageRef.current > 0) {
                              setPage(pageRef.current - 1)
                          }
                      },
                  },
                  {
                      name: '',
                      icon: 'arrow right',
                      position: 'none',
                      action: function () {
                          if (pageRef.current * 10 + 10 < countRef.current) {
                              setPage(pageRef.current + 1)
                          }
                      },
                  },
              ]

        const sortoptions = {
            recipeCode: {
                name: 'recipeCode',
                label: 'Codice ricetta',
                onChange: (obj, value) => {
                    sorts[obj.name] = value
                    setSorts(sorts)
                    setRecipes([])
                    setDirty(true)
                },
                onReset: (obj) => {
                    delete sorts[obj.name]
                    setSorts(sorts)
                    setRecipes([])
                    setDirty(true)
                },
            },
            creationDate: {
                name: 'creationDate',
                label: 'Data di creazione',
                onChange: (obj, value) => {
                    sorts[obj.name] = value
                    setSorts(sorts)
                    setRecipes([])
                    setDirty(true)
                },
                onReset: (obj) => {
                    delete sorts[obj.name]
                    setSorts(sorts)
                    setRecipes([])
                    setDirty(true)
                },
            },
        }

        const filteroptions = {
            recipeCode: {
                control: Input,
                name: 'recipeCode',
                input: 'text',
                placeholder: 'Codice ricetta',
                label: 'Codice ricetta',
                onChange: (e) => {
                    filters.recipeCode = { value: e.target.value, key: 'recipeCode', operator: '=' }
                    setFilters(filters)
                    setDirty(true)
                },
                onReset: () => {
                    setFilters(filters)
                    setRecipes([])
                    setDirty(true)
                },
            },
            recipeName: {
                control: Input,
                name: 'recipeName',
                input: 'text',
                placeholder: 'Nome ricetta',
                label: 'Nome ricetta',
                onChange: (e) => {
                    filters.recipeName = { value: e.target.value, key: 'recipeName', operator: '=' }
                    setFilters(filters)
                    setDirty(true)
                },
                onReset: () => {
                    setFilters(filters)
                    setRecipes([])
                    setDirty(true)
                },
            },
        }

        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, toolbar, filteroptions, sortoptions)
    }, [])

    return (
        <>
            <Box ml={16} mt={4}>
                Visualizzando {page * items} - {page * items + items} su {count}
            </Box>
            {recipes && recipes.length && (
                <Table celled sortable>
                    <Recipe
                        type="header"
                        sorts={sorts}
                        onRecipeCodeHeaderClick={() => {
                            if (!sorts.recipeCode) {
                                sorts.recipeCode = 'asc'
                            } else if (sorts.recipeCode === 'asc') {
                                sorts.recipeCode = 'desc'
                            } else {
                                delete sorts.recipeCode
                            }
                            setSorts(sorts)
                            setRecipes([])
                            setDirty(true)
                        }}
                    />
                    <Table.Body>
                        {recipes.map((value, index) => {
                            return (
                                <Recipe
                                    key={value.recipeId}
                                    type="table"
                                    recipe={value}
                                    onRowClick={(state) => {
                                        const { recipeId } = state
                                        setEditing(recipeId)
                                    }}
                                />
                            )
                        })}
                    </Table.Body>
                </Table>
            )}

            <Modal
                closeIcon
                open={!!editing || !!creating}
                onClose={() => {
                    setEditing(false)
                    setCreating(false)
                }}
                size="fullscreen"
            >
                <Modal.Header>{creating ? 'Creando ricetta' : 'Modificando ricetta'}</Modal.Header>
                <Modal.Content>
                    <RecipeEditor
                        recipeId={creating ? null : editing}
                        onSave={(recipe) => {
                            setEditing(false)
                            setCreating(false)
                            setRecipes([])
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                            setCreating(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let {
        getMachineList,
        deleteRecipe,
        login,
        search: { data: searchText },
    } = state

    return {
        getMachineList,
        deleteRecipe,
        login,
        searchText,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Recipes)))
