import { Box } from '@chakra-ui/layout'
import {} from 'containers'
import JsBarcode from 'jsbarcode'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes } from 'lib/routes'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import { Button, Icon, Modal, Table } from 'semantic-ui-react'
import SummarySheet from '../job/SummarySheet'
import PackageDropdown from '../utility/PackageDropdown'

const { app, packs, packEdit } = routes

class Package extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, pack } = props

        this.state = {
            deleting: false,
            ...pack,
            packMinutes: pack ? parseFloat(pack.packMinutes) : 0,
        }

        if (onCostChange && pack) {
            onCostChange(this.totalCost(), pack.packId)
        }
    }

    totalCost() {
        let { packPrice } = this.state
        let total = packPrice
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { pack, packId, network } = this.props

        if ((!pack || !pack.packId) && packId) {
            network.getPackage(packId)
        }
    }

    renderBalanceFooter() {
        return <Table.Row></Table.Row>
    }

    renderBalanceHeader() {
        return <Table.Header fullWidth></Table.Header>
    }

    renderForBalance() {
        return <Table.Row></Table.Row>
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hidePackage, hidePackageName, hideActions, hideBarcode, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hidePackage && <Table.HeaderCell>Codice</Table.HeaderCell>}
                        {!hidePackageName && <Table.HeaderCell>Imballo</Table.HeaderCell>}
                        {!hideBarcode && <Table.HeaderCell>Codice a barre</Table.HeaderCell>}
                        {!hideActions && <Table.HeaderCell>Azioni</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hidePackage, hidePackageName, isBalance, hideActions, noActions, hideBarcode, onRowClick } = this.props
        let { packageId, packageCode, packageName } = this.state

        setImmediate(() => {
            try {
                const text = packageCode
                JsBarcode(`#pakbarcode${packageCode}`, text, {
                    format: 'CODE128',
                    // lineColor: '#0aa',
                    // width: 4,
                    // height: 40,
                    // displayValue: false,
                })
            } catch (e) {
                console.error(e)
            }
        })

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hidePackage && <Table.Cell>{packageCode}</Table.Cell>}
                    {!hidePackageName && <Table.Cell>{packageName}</Table.Cell>}
                    {!hideBarcode && (
                        <Table.Cell>
                            <div className="barcode-container" ref={(el) => (this.componentRef = el)}>
                                <svg id={`pakbarcode${packageCode}`}></svg>
                            </div>
                        </Table.Cell>
                    )}
                    {!noActions && !hideActions && this.state.packageId && (
                        <Table.Cell>
                            <SummarySheet type="package" objectToSerialize={this.state} />
                            <Button
                                icon
                                color="yellow"
                                onClick={(e) => {
                                    this.setState({
                                        mergingPackage: true,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="exchange" />
                            </Button>
                            <Button
                                icon
                                color="blue"
                                onClick={(e) => {
                                    if (onRowClick) {
                                        onRowClick(this.state)
                                    }
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="edit" />
                            </Button>
                            <Button
                                icon
                                color="red"
                                onClick={(e) => {
                                    this.props.network.deletePackage({
                                        packageId,
                                        packageCode,
                                        packageName,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="remove" />
                            </Button>
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button icon color="grey">
                                            <Icon name="print" />
                                        </Button>
                                    )
                                }}
                                content={() => this.componentRef}
                            />
                        </Table.Cell>
                    )}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, pack } = this.props

        network.deleteStdPackage(pack)

        this.setState({ deleting: false })
    }

    render() {
        const { packageId, mergingPackage, mergeDestinations } = this.state
        let { login, type, pack, onClick, selected, noActions } = this.props

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(pack)
            }
        }

        let content = null
        switch (type) {
            case 'footer':
                content = this.renderTableFooter()
                break
            case 'header':
                content = this.renderTableHeader()
                break
            case 'table':
                content = this.renderForTable()
                break
            default:
                content = (
                    <Box
                        borderWidth="1px"
                        borderRadius="lg"
                        p="4"
                        overflow="hidden"
                        className={selected ? 'rowSelected' : ''}
                        style={styles.packageCard}
                        {...extraprops}
                    />
                )
                break
        }

        return (
            <>
                <Modal key={`modal_package_${packageId}`} closeIcon open={!!mergingPackage} onClose={() => this.setState({ mergingPackage: false })}>
                    <Modal.Header>Modificando package</Modal.Header>
                    <Modal.Content>
                        <PackageDropdown
                            selected={mergeDestinations}
                            onChange={(ev, data, selected, packages) => {
                                this.setState({
                                    mergeDestinations: packages,
                                })
                            }}
                            onlySelection
                        />
                        <Button
                            style={{ marginTop: 16 }}
                            color="red"
                            onClick={() => {
                                this.props.network.mergePackaging({
                                    sourceId: this.state.packageId,
                                    destinationId: this.state.mergeDestinations[0].packageId,
                                })
                                this.setState({ mergingPackage: false })
                            }}
                            disabled={
                                !this.state.mergeDestinations ||
                                this.state.mergeDestinations.length !== 1 ||
                                this.state.packageId === this.state.mergeDestinations[0].packageId
                            }
                        >
                            Effettua unione AZIONE IRREVERSIBILE, VERRA{"'"} ELIMINATO IL CONTENITORE {'"'}
                            {this.state.packageName}
                            {'"'} ({this.state.packageId})
                        </Button>
                    </Modal.Content>
                </Modal>
                {content}
            </>
        )
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    packImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    packContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    packCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getPackage } = state
    let rpackId = null
    if (login && login.data && login.data.user) rpackId = login.data.user.rpackId

    return { role: rpackId, getPackage, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Package)))
