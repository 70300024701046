import React from 'react'
import { connectNetwork } from 'lib/NetworkProvider'
import 'moment/locale/it'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { baseViewDispatch, Default, initializePage, Mobile } from '../lib/util'
import View from 'lib/View'
import Iframe from 'react-iframe'
import { Machine } from 'components'
import Material from 'printing/Material'
import WorkOrder from 'printing/WorkOrder'
import { Button, Table } from 'semantic-ui-react'
import Package from 'printing/Package'
import { getStdArticle } from 'lib/requests'
import moment from 'momentconfig'

/**
 * schermata “carica filo madre” (su macchina)
 * CTA -> CARICA FILO MADRE
 * beep macchina -> a riconoscimento macchina:
 * chiedo articolo filo madre (focus campo testo, se scansiona leggo articolo, altrimenti lo metto a mano)
 * chiedo lotto (focus campo testo, se scansiona leggo articolo, altrimenti lo metto a mano)
 * CONFERMO
 * L’evento crea un’associazione permanente tra macchina filo madre e lotto fino a nuovo evento
 **/
function CaricaBobine({
    isEmployee,
    toolbarResult,
    filtersResult,
    sortsResult,
    network,
    machines,
    packages,
    materials,
    workOrders,
    search,
    searchResult,
}) {
    const [pack, setPackage] = React.useState(null)
    const [machine, setMachine] = React.useState(null)
    const [material, setMaterial] = React.useState(null)
    const [workOrder, setWorkOrder] = React.useState(null)
    const [article, setArticle] = React.useState(null)
    const [lot, setLot] = React.useState(null)
    const [allOk, setAllOk] = React.useState(false)

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])

        network.getPackageList({}, {}, {})
        network.getMachineList('', !!isEmployee, {}, {}, -1, 10)
        network.getMaterialList('', !!isEmployee, {}, {}, -1, 10)
        network.getWorkOrderList('', {}, {}, false, false)
    }, [])

    useEffect(() => {
        if (pack) {
            const articleCode = pack.packageCode.split('-')[1]
            network.getStdArticle(undefined, articleCode).then((article) => {
                setArticle(article.data)
            })
        }
    }, [pack])

    useEffect(() => {
        if (machine && machine.activeLotId) {
            network.getLot(machine.activeLotId).then((lot) => {
                if (lot) {
                    setLot(lot.data)
                } else {
                    alert('La macchina non ha un lotto attivo')
                    setAllOk(false)
                    setMachine(null)
                    setLot(null)
                }
            })
        } else if (machine) {
            // TODO display error
            alert('La macchina non ha un lotto attivo')
            setAllOk(false)
            setMachine(null)
            setLot(null)
        }
    }, [machine])

    useEffect(() => {
        if (search.startsWith('SC') && packages && packages.length > 0) {
            for (const pack of packages) {
                if (pack.packageCode === search) {
                    setPackage(pack)
                    searchResult('')
                    break
                }
            }
        } else if (search.startsWith('MA') && machines && machines.length > 0) {
            for (const machine of machines) {
                if (machine.machineCode === search.substring(2)) {
                    setMachine(machine)
                    searchResult('')
                    break
                }
            }
        } else if (search.startsWith('MP') && materials && materials.length > 0) {
            for (const material of materials) {
                if (material.materialId === parseInt(search.substring(2), 10)) {
                    setMaterial(material)
                    searchResult('')
                    break
                }
            }
        } else {
            let code = search
            if (search.startsWith('OP')) {
                code = search.substring(2)
            }
            if (workOrders && workOrders.length > 0) {
                for (const workOrder of workOrders) {
                    if (workOrder.workOrderId === parseInt(code, 10)) {
                        setWorkOrder(workOrder)
                        searchResult('')
                        break
                    }
                }
            }
        }
    }, [search, machines, materials])

    useEffect(() => {
        /* eslint-disable no-console */
        console.log('article is', article);
        const material = article ? article.materials[0] : null;
        if (pack && lot && material && article) {
            if (lot.materialId !== material.materialId) {
                alert('Il lotto non è compatibile con il materiale')
                setAllOk(false)
                setMaterial(null)
            } else {
                setAllOk(true)
            }
        }
    }, [material, article, machine, pack, workOrder, lot])

    return (
        <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Default>
                {!pack && <div>Scansionare cassetta</div>}
                {!machine && <div>Scansionare macchina</div>}
                <Table>
                    <Package type="header" />
                    {pack && <Package key={`pack-${pack.packageId}`} type="table" noActions pack={pack} />}
                </Table>
                {machine && <Machine key={`machine-${machine.machineId}`} type="full" noActions machine={machine} />}
                {material && <Material key={`material-${material.materialId}`} type="full" noActions material={material} />}
                <Table>
                    <WorkOrder type="header" />
                    {workOrder && <WorkOrder key={`workorder-${workOrder.workOrderId}`} type="table" noActions workOrder={workOrder} />}
                </Table>
            </Default>
            <Mobile>
                {!pack && <div>Scansionare cassetta</div>}
                {!machine && <div>Scansionare macchina</div>}
                {pack && (
                    <div>
                        Imballo: {pack.packageCode} {pack.packageName}
                    </div>
                )}
                {machine && (
                    <div>
                        Macchina:{machine.machineId} {machine.machineName}
                    </div>
                )}
                {material && (
                    <div>
                        Materiale: {material.materialId} {material.materialName}
                    </div>
                )}
                {workOrder && (
                    <div>
                        OdP: {workOrder.workOrderId} {workOrder.workCode} {workOrder.workOrderDescription}
                    </div>
                )}
                {lot && <div>Lotto: {lot.lotId}</div>}
            </Mobile>
            {allOk && (
                <Button
                    color="green"
                    style={{ width: '100%', height: '18vh' }}
                    onClick={async () => {
                        alert(
                            'INFORMAZIONI CORRETTE: CREANDO ORDINE DI PRODUZIONE E INVIANDO DATI ALLA MACCHINA... (ora non invia nulla alla macchina ovviamente)'
                        )

                        const jobCodeComputed = moment().format('YYYYMMDDHHmmss')
                        network
                            .createWorkOrder({
                                workCode: article.articleCode,
                                jobCode: jobCodeComputed,
                                clientOrderCode: null,
                                product: article.articleCode,
                                workOrderDescription: article.articleDescription,
                                workOrderNotes: null,
                                productQuantity: article.packages[0].pcsPerPackage,
                                remainingQuantity: article.packages[0].pcsPerPackage,
                                articleId: article.articleId,
                                completed: 0,
                                inProduction: 1,
                            })
                            .then(async (result) => {
                                /* eslint-disable no-console */
                                console.log('Machine is ', machine)
                                if (machine.machineType === 12) {
                                    const recipeSpecificData = machine.recipeMachineSpecificData ? JSON.parse(machine.recipeMachineSpecificData) : {}

                                    /*
                                    bobina1: "1"
                                    bobina2: "1"
                                    bobina3: "0"
                                    bobina4: "0"
                                    bobinaftg: "0"
                                    centraggio: "1"
                                    corredia: "1"
                                    diafilo: "1"
                                    diamax: "1"
                                    distspire: "1"
                                    larghezza: "1.7"
                                    nocciolo: "1.5"
                                    numfili: "2"
                                    passosor: "1"
                                    posiniziale: "1"
                                    poslegatura: "1"
                                    startdir: "1"
                                    velmtmin: "1"
                                    velrotrpm: "1"
                                    velsor: "1"
                                */

                                    /* eslint-disable */
                                    console.log('Machine node base', machine.nodeBase)
                                    if (machine.nodeJob) {
                                        await network.createCommand({
                                            node: machine.nodeJob,
                                            action: 1,
                                            value: `${jobCodeComputed}`,
                                            endpoint: machine.machineEndpoint,
                                        })
                                    }

                                    await network.createCommand({
                                        node: machine.nodeBase + 'Bobina1_I',
                                        action: 1,
                                        value: `${recipeSpecificData.bobina1}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'Bobina2_I',
                                        action: 1,
                                        value: `${recipeSpecificData.bobina2}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'Bobina3_I',
                                        action: 1,
                                        value: `${recipeSpecificData.bobina3}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'Bobina4_I',
                                        action: 1,
                                        value: `${recipeSpecificData.bobina4}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'Bobina_FT_G_I',
                                        action: 1,
                                        value: `${recipeSpecificData.bobinaftg}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'Centraggio_I',
                                        action: 1,
                                        value: `${recipeSpecificData.centraggio}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'CorreDia_I',
                                        action: 1,
                                        value: `${recipeSpecificData.corredia}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'DiaFilo_I',
                                        action: 1,
                                        value: `${recipeSpecificData.diafilo}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'DiaMax_I',
                                        action: 1,
                                        value: `${recipeSpecificData.diamax}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'DistSpire_I',
                                        action: 1,
                                        value: `${recipeSpecificData.distspire}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'Larghezza_I',
                                        action: 1,
                                        value: `${recipeSpecificData.larghezza}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'Nocciolo_I',
                                        action: 1,
                                        value: `${recipeSpecificData.nocciolo}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'NumFili_I',
                                        action: 1,
                                        value: `${recipeSpecificData.numfili}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'PassoSor_I',
                                        action: 1,
                                        value: `${recipeSpecificData.passosor}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'PosIniziale_I',
                                        action: 1,
                                        value: `${recipeSpecificData.posiniziale}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'PosLegatura_I',
                                        action: 1,
                                        value: `${recipeSpecificData.poslegatura}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'StartDir_I',
                                        action: 1,
                                        value: `${recipeSpecificData.startdir}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'VelMtMin_I',
                                        action: 1,
                                        value: `${recipeSpecificData.velmtmin}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'VelRotRpm_I',
                                        action: 1,
                                        value: `${recipeSpecificData.velrotrpm}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'VelSor_I',
                                        action: 1,
                                        value: `${recipeSpecificData.velsor}`,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })

                                    await network.createCommand({
                                        node: machine.nodeBase + 'AbilitaCambio_I',
                                        action: 1,
                                        value: 1,
                                        endpoint: `${machine.machineEndpoint}`,
                                    })
                                }
                            })
                    }}
                >
                    CREA ORDINE DI PRODUZIONE
                </Button>
            )}
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getPackageList, getWorkOrderList, getMaterialList, getMachineList, getRecentMachineList, deleteMachine, search, organization, login } =
        state
    let { isEmployee } = ownProps
    let machineList = getMachineList
    if (isEmployee) {
        machineList = getRecentMachineList
    }
    return {
        search: search.data,
        packages: getPackageList.data.data,
        machines: machineList.data.data,
        fetching: machineList.fetching,
        materials: getMaterialList.data.data,
        workOrders: getWorkOrderList.data.data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(CaricaBobine)))
