/* eslint react/no-direct-mutation-state: "off" */
import { Box } from '@chakra-ui/layout'
import { Checkbox } from '@chakra-ui/react'
import { Article } from 'components'
import { Items } from 'containers'
import JsBarcode from 'jsbarcode'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { Area, AreaChart, CartesianGrid, Legend, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Button, Form, Icon, Input, Modal, Placeholder, Select, Table, Transition } from 'semantic-ui-react'
import { Confirmation, Cost, CustomTable, DataPoint, DocumentRecap, GT2000Data, ImageDefault, Machine, Work, Worker } from '../components'
import { CostEditor } from '../containers'
import BabypressaData from '../extra/BabypressaData'
import { computeGaps, computeHadesData, Default, floatTimeToString, Mobile, timeObject } from '../lib/util'
import DeliveryDateModal from '../utility/DeliveryDateModal'
import TaskModal from '../utility/TaskModal'
import MachinePicker from './MachinePicker'

const moment = require('moment-timezone')

const { app, jobs, finalBalance, jobEdit, articles, newEl, tempJobs, jobMerge, articleEdit } = routes

const COLORS = { quantity: '#00B75B', qOptimal: '#EAFAF1', scrap: '#FFD930', sMax: '#FEF9E7' }

const REASON_LABELS = {
    0: 'Setup',
    1: 'Produzione',
    2: 'Blocco',
    99: 'Termine Lavori',
}

const REASON_COLORS = ['#FDF2E9', '#E9F7EF', '#F9EBEA']
let max = 0

class Job extends Component {
    constructor(props) {
        super(props)
        this.state = this.compute()
        this.state.costDict = {}

        let { showDetails, forcedOpen } = this.props
        if (showDetails !== undefined) {
            this.state.showDetails = showDetails
        }

        if (forcedOpen) {
            this.state.showDetails = true
        }
        this.state.budgeted = 0
        this.state.billed = 0
        if (this.state.job) {
            this.state.dataUltimaFatturazione = this.state.job.dataUltimaFatturazione
        }
        this.state.budgetedModal = false
        this.state.billedModal = false
        this.state.dateModal = false
        this.state.editMapping = false
        this.state.showTaskModal = false
        this.state.showDeliveryDateModal = false

        this.state.chartData = []
        this.state.chartDataReference = []
        this.state.chartWorkOrder = '0'

        this.state.newmaprow = null
        this.state.newmapcolumn = null
        this.state.deletingMapping = false
    }

    compute(forcedJob, forcedWorks) {
        let { fixedJob } = this.props
        if (fixedJob) forcedJob = fixedJob

        let { works: fromProps } = this.props
        if (!fromProps) fromProps = []
        let works = fromProps.slice(0)
        if (forcedWorks) works = forcedWorks.slice(0)

        let workers = []
        let workerobj = {}
        let machines = []
        let machineobj = {}
        let totalWorkerTime = [0, 0]
        let totalMachineTime = [0, 0]

        let workdict = {}
        let costdict = {}
        let costs = []
        let notWorks = [] // Cost entries don't have a work attached to them

        let { costs: costsFromProps } = this.props
        if (costsFromProps) {
            costs = costsFromProps
            for (let i in costs) {
                costs[i].costPrice = parseFloat(costs[i].costPrice)
            }
        }

        for (let i in works) {
            let work = works[i]
            let {
                jobCode,
                workerId,
                machineId,
                workerName,
                machineName,
                workerHours,
                machineHours,
                workerMinutes,
                machineMinutes,
                workerHourlyRate,
                machineHourlyRate,
                workerListings,
                machineListings,
                costId,
                costDate,
                costDescription,
                producer,
                costPrice,
                registrationDate,
            } = work

            if (!costsFromProps && costId !== null && costId && !costdict[costId]) {
                costdict[costId] = {
                    costId,
                    costDate,
                    jobCode,
                    costDescription,
                    producer,
                    costPrice,
                }
                costs.push(costdict[costId])
            }

            if (!workdict[`${jobCode}_${workerId}_${registrationDate}`]) {
                workdict[`${jobCode}_${workerId}_${registrationDate}`] = true
            } else {
                notWorks.push(parseInt(i))
                continue
            }

            workerHours = parseFloat(workerHours)
            workerMinutes = parseFloat(workerMinutes)
            machineHours = parseFloat(machineHours)
            machineMinutes = parseFloat(machineMinutes)
            totalWorkerTime[0] += workerHours
            totalWorkerTime[1] += workerMinutes
            totalMachineTime[0] += machineHours
            totalMachineTime[1] += machineMinutes

            if (workerId !== null) {
                if (!workerobj[workerId]) {
                    workerobj[workerId] = []
                }

                workerobj[workerId].push({
                    workerId,
                    workerName,
                    workerHours,
                    workerMinutes,
                    workerHourlyRate,
                    workerListings,
                })
            }

            if (machineId !== null) {
                if (!machineobj[machineId]) {
                    machineobj[machineId] = []
                }

                machineobj[machineId].push({
                    machineId,
                    machineName,
                    machineHours,
                    machineMinutes,
                    machineHourlyRate,
                    machineListings,
                })
            }
        }

        for (let i = notWorks.length - 1; i >= 0; i--) {
            let toRemove = notWorks[i]
            works.splice(toRemove, 1)
        }

        for (let i in workerobj) {
            let worklist = workerobj[i]
            let { workerId, workerName } = worklist[0]
            let workerHours = 0
            let workerMinutes = 0
            let workerHourlyRate = 0
            let workerListings = []

            for (let j in worklist) {
                let work = worklist[j]
                workerHours += work.workerHours
                workerMinutes += work.workerMinutes
                workerHourlyRate = parseFloat(work.workerHourlyRate)
                workerListings = work.workerListings
            }

            workerHours += Math.floor(workerMinutes / 60)
            workerMinutes = workerMinutes % 60

            workers.push({
                workerId,
                workerName,
                workerHours,
                workerMinutes,
                workerHourlyRate,
                listings: workerListings,
            })
        }

        for (let i in machineobj) {
            let worklist = machineobj[i]
            let { machineId, machineName } = worklist[0]
            let machineHours = 0
            let machineMinutes = 0
            let machineHourlyRate = 0
            let machineListings = []

            for (let j in worklist) {
                let work = worklist[j]
                machineHours += work.machineHours
                machineMinutes += work.machineMinutes
                machineHourlyRate = parseFloat(work.machineHourlyRate)
                machineListings = work.machineListings
            }

            machineHours += Math.floor(machineMinutes / 60)
            machineMinutes = machineMinutes % 60

            machines.push({
                machineId,
                machineName,
                machineHours,
                machineMinutes,
                machineHourlyRate,
                listings: machineListings,
            })
        }

        let { job, isBalance } = this.props

        totalWorkerTime[0] += Math.floor(totalWorkerTime[1] / 60)
        totalWorkerTime[1] = totalWorkerTime[1] % 60
        totalMachineTime[0] += Math.floor(totalMachineTime[1] / 60)
        totalMachineTime[1] = totalMachineTime[1] % 60

        //console.log("Final works list", works);
        //console.log('Job forcedJob is', forcedJob)

        return {
            error: false,
            wait: true,
            exportModal: false,
            costs,
            workers,
            works,
            machines,
            totalWorkerTime,
            totalMachineTime,
            showDetails: isBalance ? true : false,
            deleting: false,
            job: forcedJob ? forcedJob : job,
            external: job ? job.createdExternal === 1 : false,
            totalWithFixedCosts: true,
            totalWithItems: true,
        }
    }

    componentDidMount() {
        let { noRequests, job, jobId, network, mappingData, isEmployee } = this.props

        if (!noRequests) {
            if ((!job || !job.jobCode) && jobId) {
                network.getJob(jobId)
                network.getBabypressaDataFilter(jobId)
                network.getGT2000DataFilter(jobId)
            }
            network.getWorkerListDebounced(isEmployee)
            network.getMachineListDebounced('', isEmployee)
        }

        if (!mappingData.data) {
            network.getMappingDataDebounced(isEmployee)
        }
    }

    componentWillUnmount() {}

    UNSAFE_componentWillReceiveProps(nextProps) {}

    renderControls() {
        let { grouped, chartWorkOrder } = this.state

        let options = []
        for (let i in grouped) {
            options.push({
                key: i,
                value: i,
                text: i,
            })
        }

        if (options.length === 0) {
            return null
        }

        return (
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-job-type"
                        control={Select}
                        options={options}
                        label={<span className="label">Ordine di lavoro</span>}
                        placeholder="Ordine di lavoro"
                        name="chartWorkOrder"
                        value={chartWorkOrder}
                        onChange={(e, data) => {
                            let id = data.value
                            this.setState({ chartWorkOrder: id })
                        }}
                    />
                </Form.Group>
            </Form>
        )
    }

    renderCharts() {
        let { chartData, chartDataReference } = this.state

        if (chartData.length === 0) {
            return null
        }

        return (
            <ResponsiveContainer width="100%" height={400}>
                <AreaChart
                    data={chartData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    onMouseOver={() => {
                        this.setState({ stopChart: true })
                    }}
                    onMouseLeave={() => {
                        this.setState({ stopChart: false })
                    }}
                >
                    <defs>
                        <linearGradient id="colorCorretti" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS.quantity} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS.quantity} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorScarti" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS.scrap} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS.scrap} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        scale="time"
                        domain={['dataMin', 'dataMax']}
                        type="number"
                        dataKey="millis"
                        tickFormatter={(value) => {
                            return moment.unix(value).format('DD/MM HH:mm:ss')
                        }}
                    />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Legend verticalAlign="top" height={36} />
                    <Tooltip
                        labelFormatter={(value) => {
                            return moment.unix(value).format('DD/MM HH:mm:ss')
                        }}
                    />

                    {chartDataReference.map((value, index) => {
                        return (
                            <ReferenceArea
                                key={index}
                                ifOverflow="extendDomain"
                                // alwaysShow={true}
                                label={value.label}
                                x1={value.x1}
                                x2={value.x2}
                                y1={value.y1}
                                y2={value.y2}
                                fill={value.fillColor}
                                fillOpacity={value.opacity}
                            />
                        )
                    })}
                    <Area type="monotone" dataKey="corretti" stroke={COLORS.quantity} fillOpacity={1} fill="url(#colorCorretti)" />
                    <Area type="monotone" dataKey="scarti" stroke={COLORS.scrap} fillOpacity={1} fill="url(#colorScarti)" />
                    {/* <ReferenceLine y={350} label='Ottimalè stroke='red' strokeDasharray='3 3' /> */}
                </AreaChart>
            </ResponsiveContainer>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            getJob,
            costs,
            showDetails,
            jobId,
            works: worksFromProps,
            network,
            getFromId,
            login,
            organization,
            fetchingMap,
            mappingData,
            deleteCost,
        } = this.props

        if (!deleteCost.fetching && prevProps.deleteCost.fetching && deleteCost.request_data.cost.costId) {
            let { costs } = this.state
            let toRemove = null
            for (const index in costs) {
                const cost = costs[index]
                if (cost.costId === deleteCost.request_data.cost.costId) {
                    toRemove = index
                    break
                }
            }

            if (toRemove !== null) {
                costs.splice(toRemove, 1)
                this.setState({ costs })
            }
        }

        let { chartWorkOrder } = this.state

        // TODO reload (call getjob) when company changes, and redirect to home is the job doesn't exist in the new company

        if (getJob && getJob.fetching !== prevProps.getJob.fetching && !getJob.fetching) {
            if (getJob.status.success && getJob.data[jobId]) {
                //console.log(getJob.data);
                let job = getJob.data[jobId][0]
                let works = getJob.data[jobId]
                if (worksFromProps) {
                    works = worksFromProps
                }
                let newState = this.compute(job, works)
                this.setState({ job, works, error: false, ...newState })

                //console.log('Got job, now checking if in need of hades request', login && login.data.user && login.data.user.companies, login)
                if (login && login.data.user && login.data.user.companies) {
                    let company = login.data.user.companies[organization.data[0]]
                    //console.log('Getting from id both filter and not')
                    // TODO get from new APIs
                    // network.getFromIdFiltering(company, jobId, [
                    //     {
                    //         key: 'timestamp',
                    //         symbol: '>',
                    //         value: moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                    //     },
                    // ])
                    // network.getFromId(company, jobId)
                }
                return
            } else {
                this.setState({ error: true })
            }
        }

        //console.log('Job Did update job:', prevProps.job, this.props.job, jobId)
        if (!jobId && prevProps.job && prevProps.job.jobCode !== this.props.job.jobCode) {
            this.setState({
                job: this.props.job,
            })
        }

        if (getFromId && getFromId.fetching !== prevProps.getFromId.fetching && !getFromId.fetching) {
            this.setState(computeHadesData(this.props))
        }

        if (
            (getFromId && getFromId.fetching !== prevProps.getFromId.fetching && !getFromId.fetching) ||
            chartWorkOrder !== prevState.chartWorkOrder
        ) {
            if (getFromId.status.success && getFromId.data) {
                //console.log('getFromId data is:', getFromId)

                let {
                    // getFromId,
                    getFromId: {
                        request_data: { id: requestedJob },
                        data: { data: hadesData },
                    },
                } = this.props

                if (!this.state.job || this.state.job.jobCode !== requestedJob) {
                    return null
                }

                //console.log('Data from filtering is', hadesData)

                let fixedData = []
                if (hadesData) {
                    let condensed = []
                    for (let i = 0; i < hadesData.length; i++) {
                        if (i % 1 === 0) {
                            // TODO choose resolution
                            hadesData[i].millis = new Date(hadesData[i].timestamp).getTime() / 1000
                            condensed.push(hadesData[i])
                        }
                    }
                    fixedData = condensed
                }

                //console.log('fixedData is: ', fixedData)

                let _data = []
                if (this.state.buffer) {
                    for (let i in this.state.buffer) {
                        this.state.buffer[i].millis = new Date(this.state.buffer[i].timestamp).getTime() / 1000
                    }
                    _data = [...fixedData, ...this.state.buffer]
                } else {
                    _data = [...fixedData]
                }

                let { chartDataReference, chartData } = this.state

                if (_data.length === 0) return null

                chartDataReference = []
                chartData = []
                _data.sort((a, b) => {
                    return a.millis - b.millis
                })

                //console.log('fixedData is: ', _data)

                for (let d of _data) {
                    let { timestamp, millis, quantity, scrap, reason, id, sub_id, terminal } = d

                    if (quantity < 0) quantity = 0
                    if (scrap < 0) scrap = 0

                    if (sub_id !== chartWorkOrder) {
                        //console.log('Skipping data of different work order (', sub_id, ')')
                        // Skip data not from this work order
                        continue
                    }

                    if (chartData.some((x) => x.timestamp === timestamp)) {
                        //console.log('already in', timestamp);
                        // if alreasy has that timestamp
                        continue
                    }

                    let maxQty = Math.max(quantity, scrap)
                    if (maxQty > max) max = maxQty

                    if (chartDataReference.length !== 0) {
                        let last = chartDataReference.splice(-1)[0]
                        if (last.id === reason) {
                            last.x2 = millis
                        } else {
                            last.x2 = millis
                            chartDataReference.push({ ...last })
                            last.id = reason
                            last.x1 = millis
                            last.x2 = millis
                            last.label = REASON_LABELS[reason]
                            last.fillColor = REASON_COLORS[reason]
                        }
                        chartDataReference.push(last)
                    } else {
                        let current = {
                            id: reason,
                            label: REASON_LABELS[reason],
                            x1: millis,
                            x2: millis,
                            y1: 0,
                            fillColor: REASON_COLORS[reason],
                            opacity: '0.5',
                        }
                        chartDataReference.push(current)
                    }
                    for (let item of chartDataReference) {
                        item.y2 = max + 50
                    }
                    chartData.push({
                        timestamp,
                        millis,
                        corretti: quantity,
                        scarti: scrap,
                        commessa: id,
                        lavoro: sub_id,
                        macchina: terminal,
                    })
                }
                let { quantity, scrap } = _data.pop()
                let radialQuantity = [
                    {
                        name: 'Ottimale',
                        value: 1000,
                        fill: COLORS.qOptimal,
                    },
                    {
                        name: 'quatita',
                        value: quantity,
                        fill: COLORS.quantity,
                    },
                ]
                let radialScrap = [
                    {
                        name: 'Massimo',
                        value: 500,
                        fill: COLORS.sMax,
                    },
                    {
                        name: 'scarti',
                        value: scrap,
                        fill: COLORS.scrap,
                    },
                ]

                return this.setState({
                    chartData: [...chartData],
                    chartDataReference: [...chartDataReference],
                    buffer: [],
                    radialQuantity,
                    radialScrap,
                })
            }
        }

        // let newState = this.compute(job, works)
        // this.setState({ job, works, ...newState })
        // return

        if (costs !== prevProps.costs) {
            this.setState(this.compute(this.state.job, this.state.works))
            return
        }

        if (!!showDetails !== !!prevProps.showDetails) {
            this.setState({ showDetails: !!showDetails })
        }
    }

    renderCompact() {
        let { onClick, selected } = this.props
        let { job } = this.state

        let extraProps = {
            onClick: onClick
                ? () => {
                      onClick(job)
                  }
                : null,
        }

        return (
            <Box className={selected ? 'rowSelected' : ''} {...extraProps} style={compact_styles.jobBox}>
                <Box style={compact_styles.jobContent}>
                    <View fullw column>
                        {job.jobType !== 3 && (
                            <div style={compact_styles.imageContainer}>
                                <ImageDefault fromRoot src={job.image} ui={false} style={compact_styles.jobImage} />
                            </div>
                        )}
                        <View fullw column>
                            <span style={compact_styles.header}>{job.jobName ? job.jobName : job.jobCode}</span>
                            {!!job.jobName && (
                                <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase" ml="2">
                                    {job.jobCode}
                                </Box>
                            )}
                            <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase" ml="2">
                                {job.jobDescription ? job.jobDescription : job.description}
                            </Box>
                            <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase" ml="2">
                                Programma: {job.programmaCommessa}
                            </Box>
                            <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated style={{ textTransform: 'capitalize' }}>
                                {moment(job.creationDate).format('LLLL')}
                            </Box>
                        </View>
                    </View>
                </Box>
            </Box>
        )
    }

    onCostChange(newCost, shorthand) {
        let { costDict, totalWithFixedCosts, totalWithItems } = this.state
        let totalBalanceValue = 0
        costDict[shorthand] = newCost

        for (let i in costDict) {
            //console.log('Cost dict key: ', i)
            if (i === 'cost' && !totalWithFixedCosts) continue
            if (i === 'item' && !totalWithItems) continue
            totalBalanceValue += costDict[i]
        }

        this.setState({
            costDict,
            totalBalanceValue,
        })
    }

    renderDataTable() {
        let { grouped } = this.state
        let { isBalance } = this.props

        let extraProps = {}
        if (isBalance) {
            extraProps = {
                isBalance,
                onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
            }
        }

        let orderBy = (a, b) => {
            return a.millis - b.millis
        }

        const stats = computeGaps(grouped)

        return <CustomTable {...extraProps} orderBy={orderBy} name="Dati" shorthand="data" values={stats} child={DataPoint} />
    }

    renderGT2000Table() {
        let { isBalance, getGT2000DataFilter } = this.props

        //console.log('Rendering GT2000 table with data', getGT2000DataFilter)
        if (!getGT2000DataFilter || !getGT2000DataFilter.data) return null

        let gt2000datalist = getGT2000DataFilter.data

        return <CustomTable name="Dati GT2000 relativi alla commessa" shorthand="gt2000data" values={gt2000datalist.data} child={GT2000Data} />
    }

    renderBabypressaTable() {
        let { isBalance, getBabypressaDataFilter } = this.props
        //console.log('Rendering BabyPressa table with data', getBabypressaDataFilter)
        if (!getBabypressaDataFilter || !getBabypressaDataFilter.data) return null

        let babypressadatalist = getBabypressaDataFilter.data

        return (
            <CustomTable
                name="Dati Babypressa relativi alla commessa"
                shorthand="babypressadata"
                values={babypressadatalist.data}
                child={BabypressaData}
            />
        )
    }

    // renderMachineTable() {
    //     let { grouped } = this.state
    //     let { isBalance } = this.props
    //     let extraProps = {}
    //     if (isBalance) {
    //         extraProps = {
    //             isBalance,
    //             onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
    //         }
    //     }

    //     let orderBy = (a, b) => {
    //         return a.millis - b.millis
    //     }

    //     const stats = computeGaps(grouped)

    //     return <CustomTable {...extraProps} orderBy={orderBy} name="Dati" shorthand="data" values={stats} child={DataPoint} />

    //     // return <CustomTable {...extraProps} name='Macchinè shorthand='machinè values={machines} child={Machine} />
    // }

    computeMachineTimes() {
        let { machines, hadesMachines } = this.state
        let { machineList } = this.props

        if (!hadesMachines) {
            if (!machines) {
                return []
            } else {
                return machines
            }
        }

        if (machineList) {
            for (let i in hadesMachines) {
                let machine = hadesMachines[i]
                let { machineIdentifier } = machine
                let timeTotal = 0
                for (let i in machine.workSpans) {
                    timeTotal += machine.workSpans[i].size
                }

                let seconds = timeTotal / 1000
                let minutes = seconds / 60
                let workHours = minutes / 60
                let workMinutes = (workHours % 1) * 60
                workHours = Math.floor(workHours)

                for (let reference of machineList) {
                    //console.log('Checking', machineIdentifier, reference.machineIdentifier)
                    if (reference.machineIdentifier === machineIdentifier) {
                        let skip = false
                        for (let toCheck in machines) {
                            if (machines[toCheck].machineId === reference.machineId) {
                                // Skip this one because it's already present
                                // TODO add machine time instead of replacing
                                skip = true
                                machines[toCheck].machineHours = workHours
                                machines[toCheck].machineMinutes = workMinutes
                                machines[toCheck].time = timeTotal
                                break
                            }
                        }

                        /*
							machineId,
							machineName,
							machineHours,
							machineMinutes,
							machineHourlyRate
						*/

                        //console.log('About to add machine', reference, skip)
                        if (!skip) {
                            machines.push({
                                ...reference,
                                machineHours: workHours,
                                machineMinutes: workMinutes,
                                time: timeTotal,
                            })
                        }
                    }
                }
            }
        }

        return machines
    }

    renderMachineTable(machines) {
        let { isBalance } = this.props
        let extraProps = {}
        if (isBalance) {
            extraProps = {
                isBalance,
                onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
            }
        }

        //console.log('Machines are', machines)

        return (
            <CustomTable
                {...extraProps}
                listingIndex={this.props.listingIndex ? this.props.listingIndex : 0}
                showNoMachine
                name="Macchine"
                shorthand="machine"
                values={machines}
                child={Machine}
            />
        )
    }

    computeWorkerTimes() {
        let { workers, hadesWorkers } = this.state
        let { workerList } = this.props

        if (!hadesWorkers) {
            if (!workers) {
                return []
            } else {
                return workers
            }
        }

        if (workerList) {
            for (let i in hadesWorkers) {
                let worker = hadesWorkers[i]
                let { workerIdentifier } = worker
                let timeTotal = 0
                for (let i in worker.workSpans) {
                    timeTotal += worker.workSpans[i].size
                }

                let seconds = timeTotal / 1000
                let minutes = seconds / 60
                let workHours = minutes / 60
                let workMinutes = (workHours % 1) * 60
                workHours = Math.floor(workHours)

                for (let reference of workerList) {
                    if (reference.workerIdentifier === workerIdentifier) {
                        let skip = false
                        for (let toCheck in workers) {
                            if (workers[toCheck].workerId === reference.workerId) {
                                // Skip this one because it's already present
                                // TODO add worker time instead of replacing
                                skip = true
                                workers[toCheck].workerHours = workHours
                                workers[toCheck].workerMinutes = workMinutes
                                workers[toCheck].time = timeTotal
                                break
                            }
                        }

                        /*
							workerId,
							workerName,
							workerHours,
							workerMinutes,
							workerHourlyRate
						*/

                        if (!skip) {
                            workers.push({
                                ...reference,
                                workerHours: workHours,
                                workerMinutes: workMinutes,
                                time: timeTotal,
                            })
                        }
                    }
                }
            }
        }

        return workers
    }

    renderWorkerTable(workers) {
        let { isBalance } = this.props
        let extraProps = {}
        if (isBalance) {
            extraProps = {
                isBalance,
                onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
            }
        }

        //console.log('Workers are', workers)

        return (
            <CustomTable
                {...extraProps}
                listingIndex={this.props.listingIndex ? this.props.listingIndex : 0}
                name="Impiegati"
                shorthand="worker"
                values={workers}
                child={Worker}
            />
        )
    }

    renderWorkTable() {
        let { works } = this.state
        let { isBalance } = this.props
        let extraProps = {}

        if (isBalance) {
            extraProps = {
                isBalance,
                onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
            }
        }
        return <CustomTable {...extraProps} name="Lavori" shorthand="work" values={works} child={Work} required="registrationDate" />
    }

    renderCostTable() {
        let { costs } = this.state
        let { isBalance } = this.props
        let extraProps = {}
        if (isBalance) {
            extraProps = {
                isBalance,
                onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
            }
        }
        return <CustomTable {...extraProps} name="Costi" shorthand="cost" values={costs} child={Cost} />
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, job } = this.props

        network.deleteJob(job)

        this.setState({ deleting: false })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    drawBarcode = () => {
        if (!this.props.noBarcode) {
            try {
                const text = this.state.job.jobName ? this.state.job.jobName : this.state.job.jobCode
                const el = document.getElementById(`barcode${this.state.job.jobCode}`)
                if (el) {
                    JsBarcode(`#barcode${this.state.job.jobCode}`, text, {
                        format: 'CODE128',
                        // lineColor: '#0aa',
                        // width: 4,
                        // height: 40,
                        // displayValue: false,
                    })
                } else {
                    setImmediate(this.drawBarcode)
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    renderBoxContent(user, workerTimeObject, machineTimeObject, direction) {
        let { mappingData, type, noHours, noActions, role } = this.props
        let {
            budgeted,
            billed,
            dataUltimaFatturazione,
            job,
            budgetedModal,
            billedModal,
            dateModal,
            editMapping,
            newmaprow,
            newmapcolumn,
            showBigImage,
        } = this.state

        let extraViewProps = {
            row: (type === 'full' && !direction) || direction === 'row',
            column: (type !== 'full' && !direction) || direction === 'column',
        }

        setImmediate(this.drawBarcode)

        let materialTotal = 0
        let masterTotal = 0
        let weightTotal = 0
        let quantityTotal = 0

        if (job.articles) {
            for (const article of job.articles) {
                if (article.productQuantity) {
                    quantityTotal += parseFloat(article.productQuantity)
                    weightTotal += article.articleWeight

                    if (article.masters) {
                        for (const master of article.masters) {
                            masterTotal += (master.masterPercent / 100) * article.articleWeight
                        }
                    }

                    if (article.materials) {
                        for (const material of article.materials) {
                            materialTotal += (material.materialPercent / 100) * article.articleWeight
                        }
                    }
                }
            }
        }

        return (
            <div ref={(el) => (this.componentRef = el)}>
                <View fullw {...extraViewProps} around>
                    {job.jobType !== 3 && (
                        <>
                            <Mobile>
                                {!showBigImage && <div />}
                                {showBigImage && (
                                    <div
                                        onClick={() => {
                                            this.setState({ showBigImage: false })
                                        }}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(0, 0, 0, 0.37)',
                                        }}
                                    >
                                        <TransformWrapper style={{ height: '100%', width: '100%' }}>
                                            <TransformComponent style={{ height: '100%', width: '100%' }}>
                                                <ImageDefault fromRoot src={job.image} ui={false} style={{ height: '100%', width: '100%' }} />
                                            </TransformComponent>
                                        </TransformWrapper>
                                    </div>
                                )}
                            </Mobile>
                            <Default>
                                {!showBigImage && <div />}
                                {showBigImage && (
                                    <div
                                        onClick={() => {
                                            this.setState({ showBigImage: false })
                                        }}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            width: '100%',
                                            height: '100%',
                                            maxHeight: 300,
                                            backgroundColor: 'rgba(0, 0, 0, 0.37)',
                                        }}
                                    >
                                        <TransformWrapper style={{ height: '100%', width: '100%', maxHeight: 300 }}>
                                            <TransformComponent style={{ height: '100%', width: '100%', maxHeight: 300 }}>
                                                <ImageDefault
                                                    fromRoot
                                                    src={job.image}
                                                    ui={false}
                                                    style={{ height: '100%', width: '100%', maxHeight: 300 }}
                                                />
                                            </TransformComponent>
                                        </TransformWrapper>
                                    </div>
                                )}
                            </Default>

                            <View around style={extraViewProps.row ? styles.imageContainer : styles.imageContainerMobile}>
                                <ImageDefault
                                    fromRoot
                                    src={job.image}
                                    ui={false}
                                    style={extraViewProps.row ? styles.jobImage : styles.jobImageMobile}
                                    onClick={() => {
                                        this.setState({
                                            showBigImage: true,
                                        })
                                    }}
                                />
                            </View>
                        </>
                    )}
                    <View fullw {...extraViewProps} around>
                        <View
                            fullw
                            column
                            style={{
                                maxWidth: '95%',
                            }}
                        >
                            <Default>
                                <span style={styles.header}>{job.jobName ? job.jobName : job.jobCode}</span>
                            </Default>
                            <Mobile>
                                <span style={styles.smallHeader}>{job.jobName ? job.jobName : job.jobCode}</span>
                            </Mobile>
                            {!!job.jobName && (
                                <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase" ml="2">
                                    {job.jobCode}
                                </Box>
                            )}
                            {!!job.jobClientCode && (
                                <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase" ml="2">
                                    {job.jobClientCode}
                                </Box>
                            )}
                            <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase" ml="2">
                                {job.jobDescription ? job.jobDescription : job.description}
                            </Box>
                            {job.jobLocation && (
                                <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated style={{ textTransform: 'capitalize' }}>
                                    Pedana: {job.jobLocation}
                                </Box>
                            )}
                            <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated style={{ textTransform: 'capitalize' }}>
                                Tipologia macchina: {!job.jobMachineType || job.jobMachineType === 0 ? 'Normale' : 'Baby'}
                            </Box>
                            <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated style={{ textTransform: 'capitalize' }}>
                                Creata {job.creationDate ? moment(job.creationDate).format('LLLL') : moment().format('LLLL')}
                            </Box>
                            <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated style={{ textTransform: 'capitalize' }}>
                                Ordinato: {job.orderDate ? moment(job.orderDate).format('LL') : moment().format('LL')}
                            </Box>
                            {mappingData && mappingData.data && job && job.jobCode && mappingData.data[job.jobCode] && (
                                <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
                                    Posizione dello stampo nell{"'"}armadio: RIGA {mappingData.data[job.jobCode].row} COLONNA{' '}
                                    {mappingData.data[job.jobCode].column}{' '}
                                    <Icon
                                        onClick={() => {
                                            this.setState({
                                                deletingMapping: true,
                                            })
                                        }}
                                        name="trash"
                                    />
                                </Box>
                            )}
                            {job.workOrders
                                ? job.workOrders.map((value, index) => {
                                      return (
                                          <Box
                                              key={`job-work-order-${value.workOrderId}`}
                                              mt="1"
                                              fontWeight="semibold"
                                              as="h3"
                                              lineHeight="tight"
                                              isTruncated
                                          >
                                              {value.workCode} {value.productQuantity ? `, ${value.productQuantity}PZ` : ''}
                                          </Box>
                                      )
                                  })
                                : null}
                            {!noActions && user && !!user.manage_job && (
                                <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
                                    Ultima fatturazione: {job.lastBillDate ? moment(job.lastBillDate).format('LL') : '----'}{' '}
                                    <Icon
                                        onClick={() => {
                                            // Format: YYYY-MM-DD
                                            let momobj = moment(job.lastBillDate)

                                            let data = new Date()
                                            if (momobj.isValid()) {
                                                data = momobj.toDate()
                                            }

                                            this.setState({
                                                lastBillDate: data,
                                                dateModal: true,
                                            })
                                        }}
                                        name="edit"
                                    />
                                    <Modal open={dateModal}>
                                        <Modal.Header>Modifica cifra preventivata commessa {job.jobName ? job.jobName : job.jobCode}</Modal.Header>
                                        <Modal.Content>
                                            <Form>
                                                <Form.Field
                                                    control={DatePicker}
                                                    label={<span className="label">Data ultima fatturazione</span>}
                                                    name="dataUltimaFatturazione"
                                                    value={dataUltimaFatturazione}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={dataUltimaFatturazione}
                                                    onChange={(data) => {
                                                        this.setState({
                                                            dataUltimaFatturazione: data,
                                                        })
                                                    }}
                                                />
                                            </Form>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button
                                                primary
                                                onClick={() => {
                                                    this.setState({ dateModal: false })
                                                }}
                                            >
                                                Annulla <Icon name="check" />
                                            </Button>
                                            <Button
                                                primary
                                                onClick={() => {
                                                    let { job, dataUltimaFatturazione } = this.state
                                                    let { network } = this.props
                                                    job.dataUltimaFatturazione = dataUltimaFatturazione
                                                    this.setState({ job, dateModal: false })

                                                    let { jobCode } = job
                                                    network.createJob(jobCode, {
                                                        jobCode,
                                                        dataUltimaFatturazione,
                                                    })
                                                }}
                                            >
                                                Conferma <Icon name="check" />
                                            </Button>
                                        </Modal.Actions>
                                    </Modal>
                                </Box>
                            )}

                            {!this.props.noBarcode && (
                                <div className="barcode-container">
                                    <svg id={`barcode${job.jobCode}`}></svg>
                                </div>
                            )}

                            <Modal open={editMapping}>
                                <Modal.Header>Modifica mappatura stampo {job.jobName ? job.jobName : job.jobCode}</Modal.Header>
                                <Modal.Content>
                                    <Form>
                                        <Form.Group widths="equal">
                                            <Form.Field
                                                control={Input}
                                                label={<span className="label">Riga</span>}
                                                name="row"
                                                type="number"
                                                value={newmaprow}
                                                onChange={(value) => {
                                                    this.setState({ newmaprow: value.target.value })
                                                }}
                                            />
                                            <Form.Field
                                                control={Input}
                                                label={<span className="label">Colonna</span>}
                                                name="column"
                                                type="number"
                                                value={newmapcolumn}
                                                onChange={(value) => {
                                                    this.setState({ newmapcolumn: value.target.value })
                                                }}
                                            />
                                        </Form.Group>
                                    </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button
                                        primary
                                        onClick={() => {
                                            this.setState({ editMapping: false })
                                        }}
                                    >
                                        Annulla <Icon name="check" />
                                    </Button>
                                    <Button
                                        primary
                                        onClick={() => {
                                            let { job, newmaprow, newmapcolumn } = this.state
                                            let { network } = this.props

                                            network.createMapping({
                                                jobCode: job.jobCode,
                                                row: newmaprow,
                                                column: newmapcolumn,
                                            })

                                            this.setState({
                                                newmaprow: null,
                                                newmapcolumn: null,
                                                editMapping: false,
                                            })
                                        }}
                                    >
                                        Conferma <Icon name="check" />
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        </View>
                        {job.articles && job.articles.length > 0 && (
                            <View fullw column>
                                <View style={{ marginTop: 64, minHeight: 103 }} fullw row around className="no-print">
                                    <Box alignItems="center">
                                        <Box fontSize="lg" textAlign={['center', 'center']}>
                                            Quantit{'à'} totale
                                        </Box>
                                        <Box fontSize="4xl" textStyle="buc" mb={0} textAlign={['center', 'center']}>
                                            {Math.round(quantityTotal)}
                                        </Box>
                                    </Box>
                                    {/* <Box alignItems="center">
                                        <Box fontSize="lg" textAlign={['center', 'center']}>
                                            Peso totale
                                        </Box>
                                        <Box fontSize="4xl" textStyle="buc" mb={0} textAlign={['center', 'center']}>
                                            {weightTotal}kg
                                        </Box>
                                    </Box> */}
                                </View>
                                {/* <View style={{ minHeight: 103 }} fullw row around className="no-print">
                                    <Box alignItems="center">
                                        <Box fontSize="lg" textAlign={['center', 'center']}>
                                            Materiale totale
                                        </Box>
                                        <Box fontSize="4xl" textStyle="buc" mb={0} textAlign={['center', 'center']}>
                                            {materialTotal ? Math.round(materialTotal * 100) / 100 : '--'}kg
                                        </Box>
                                    </Box>
                                    <Box alignItems="center">
                                        <Box fontSize="lg" textAlign={['center', 'center']}>
                                            Master totale
                                        </Box>
                                        <Box fontSize="4xl" textStyle="buc" mb={0} textAlign={['center', 'center']}>
                                            {masterTotal ? Math.round(masterTotal * 100) / 100 : '--'}kg
                                        </Box>
                                    </Box>
                                </View> */}
                            </View>
                        )}
                        <View fullw column>
                            <View fullw column style={{ marginTop: 4, justifyContent: 'center', minHeight: 60 }}>
                                {job.client && (
                                    <View fullw row style={{ justifyContent: 'center', textAlign: 'center' }}>
                                        <span style={{ fontWeight: 'lighter', marginRight: 16 }}>Cliente:</span>

                                        <Box mt="1" fontWeight="semibold" as="h3" lineHeight="tight" isTruncated style={{ marginTop: 3 }}>
                                            {job.client}
                                        </Box>
                                    </View>
                                )}
                                {job.deliveryDate && (
                                    <View fullw row style={{ marginTop: 4, justifyContent: 'center', textAlign: 'center' }}>
                                        <span style={{ fontWeight: 'lighter', marginRight: 16 }}>Consegna:</span>

                                        <Box mt="1" fontWeight="semibold" as="h3" lineHeight="tight" isTruncated style={{ marginTop: 3 }}>
                                            {moment(job.deliveryDate).format('DD/MM/YYYY')}
                                        </Box>
                                        <span style={{ fontWeight: 'lighter', marginLeft: 8 }}>
                                            (Settimana{' '}
                                            <span style={{ fontWeight: 'bold', marginTop: 0 }}>{moment(job.deliveryDate).get('week')}</span>)
                                        </span>
                                    </View>
                                )}
                            </View>
                            {!noHours && !noActions && (
                                <View style={{ minHeight: 103 }} fullw row around className="no-print">
                                    <Box alignItems="center">
                                        <Box fontSize="lg" textAlign={['center', 'center']}>
                                            Ore operaio
                                        </Box>
                                        <Box fontSize="4xl" textStyle="buc" mb={0} textAlign={['center', 'center']}>
                                            {workerTimeObject.hstring}
                                        </Box>
                                        <Box fontSize="4xl" textStyle="buc" mt={0} textAlign={['center', 'center']}>
                                            {workerTimeObject.mstring}
                                        </Box>
                                    </Box>
                                    <Box alignItems="center">
                                        <Box fontSize="lg" textAlign={['center', 'center']}>
                                            Ore macchina
                                        </Box>
                                        <Box fontSize="4xl" textStyle="buc" mb={0} textAlign={['center', 'center']}>
                                            {machineTimeObject.hstring}
                                        </Box>
                                        <Box fontSize="4xl" textStyle="buc" mt={0} textAlign={['center', 'center']}>
                                            {machineTimeObject.mstring}
                                        </Box>
                                    </Box>
                                </View>
                            )}
                            {!noActions && (!role || role === 'admin') && (
                                <View fullw row around className="no-print">
                                    <Box style={{ marginBottom: 0, alignSelf: 'center' }}>
                                        <Box fontSize="lg">
                                            Preventivato{' '}
                                            <Icon
                                                onClick={() => {
                                                    this.setState({
                                                        budgeted: this.state.job.budgeted,
                                                        budgetedModal: true,
                                                    })
                                                }}
                                                name="edit"
                                            />
                                            <Modal open={budgetedModal}>
                                                <Modal.Header>
                                                    Modifica cifra preventivata commessa {job.jobName ? job.jobName : job.jobCode}
                                                </Modal.Header>
                                                <Modal.Content>
                                                    <Form>
                                                        <Form.Field
                                                            control={Input}
                                                            type="number"
                                                            label={<span className="label">Preventivato</span>}
                                                            placeholder="0.0"
                                                            step="0.1"
                                                            name="budgeted"
                                                            value={budgeted}
                                                            className="rightTextField"
                                                            onChange={(e) => this.handleInput(e)}
                                                        />
                                                    </Form>{' '}
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button
                                                        primary
                                                        onClick={() => {
                                                            this.setState({ budgetedModal: false })
                                                        }}
                                                    >
                                                        Annulla <Icon name="check" />
                                                    </Button>
                                                    <Button
                                                        primary
                                                        onClick={() => {
                                                            let { job, budgeted } = this.state
                                                            let { network } = this.props
                                                            job.budgeted = budgeted
                                                            this.setState({ job, budgetedModal: false })

                                                            let { jobCode } = job
                                                            network.createJob(jobCode, {
                                                                jobCode,
                                                                budgeted,
                                                            })
                                                        }}
                                                    >
                                                        Conferma <Icon name="check" />
                                                    </Button>
                                                </Modal.Actions>
                                            </Modal>
                                        </Box>
                                        <Box fontSize="4xl" className="smallestBox" textAlign={['center', 'center']}>
                                            {job.budgeted ? job.budgeted : '----'}€
                                        </Box>
                                    </Box>
                                    <Box style={{ alignSelf: 'center' }}>
                                        <Box fontSize="lg">
                                            Fatturato{' '}
                                            <Icon
                                                onClick={() => {
                                                    this.setState({
                                                        budgeted: this.state.job.budgeted,
                                                        billedModal: true,
                                                    })
                                                }}
                                                name="edit"
                                            />
                                            <Modal open={billedModal}>
                                                <Modal.Header>
                                                    Modifica cifra preventivata commessa {job.jobName ? job.jobName : job.jobCode}
                                                </Modal.Header>
                                                <Modal.Content>
                                                    <Form>
                                                        <Form.Group widths="equal">
                                                            <Form.Field
                                                                control={Input}
                                                                type="number"
                                                                label={<span className="label">Fatturato</span>}
                                                                placeholder="0.0"
                                                                step="0.1"
                                                                name="billed"
                                                                value={billed}
                                                                className="rightTextField"
                                                                onChange={(e) => this.handleInput(e)}
                                                            />
                                                        </Form.Group>
                                                    </Form>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button
                                                        primary
                                                        onClick={() => {
                                                            this.setState({ billedModal: false })
                                                        }}
                                                    >
                                                        Annulla <Icon name="check" />
                                                    </Button>
                                                    <Button
                                                        primary
                                                        onClick={() => {
                                                            let { job, billed } = this.state
                                                            let { network } = this.props
                                                            job.billed = billed
                                                            this.setState({ job, billedModal: false })

                                                            let { jobCode } = job
                                                            network.createJob(jobCode, {
                                                                jobCode,
                                                                billed,
                                                            })
                                                        }}
                                                    >
                                                        Conferma <Icon name="check" />
                                                    </Button>
                                                </Modal.Actions>
                                            </Modal>
                                        </Box>
                                        <Box fontSize="4xl" className="smallestBox" textAlign={['center', 'center']}>
                                            {job.billed ? job.billed : '----'}€
                                        </Box>
                                    </Box>
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            </div>
        )
    }

    renderExportModal() {
        let { job, exportModal, wait } = this.state

        let articlecode = ''

        if (job && job.jobCode && job.jobCode.length > 8) {
            articlecode = job.jobCode.substring(0, 7)
        }

        return (
            <Modal open={exportModal}>
                <Modal.Header>Esportazione documenti</Modal.Header>
                <Modal.Content>
                    Esportazione documenti per la commessa {job.jobName ? job.jobName : job.jobCode}
                    Premi il pulsante calcola per avviare il processo, poi scarica quando e{"'"} tutto pronto
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        icon
                        onClick={() => {
                            this.setState({ wait: true, exportModal: false })
                        }}
                    >
                        Annulla <Icon name="cancel" />
                    </Button>
                    <Button
                        primary
                        icon
                        onClick={() => {
                            this.setState({ wait: false })
                        }}
                    >
                        Calcola <Icon name="computer" />
                    </Button>
                    <DocumentRecap
                        wait={wait}
                        articlecode={articlecode}
                        onDone={(data) => {
                            //console.log('On done called', data)
                            this.setState({ wait: true })
                        }}
                        element={
                            <Button
                                primary
                                icon
                                onClick={() => {
                                    this.setState({ wait: true, exportModal: false })
                                }}
                            >
                                Scarica <Icon name="download" />
                            </Button>
                        }
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    render() {
        let { login, type, noActions, onClick, selected, isBalance, onOpened, forcedOpen, role, mappingData, network } = this.props
        let {
            color,
            job,
            deleting,
            totalBalanceValue,
            totalWorkerTime,
            totalMachineTime,
            totalWithItems,
            totalWithFixedCosts,
            costDict,
            external,
            machines,
            workers,
            deletingMapping,
            showTaskModal,
            showDeliveryDateModal,
            showCostDialog,
            error,
        } = this.state

        if (error) {
            return null
        }

        if (!job) {
            return (
                <Box style={{ width: '100%', margin: 8 }}>
                    <Box>
                        <Placeholder>
                            <Placeholder.Header image>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Paragraph>
                        </Placeholder>
                    </Box>
                </Box>
            )
        }

        let partial = (costDict['worker'] ? costDict['worker'] : 0) + (costDict['machine'] ? costDict['machine'] : 0)

        let totalhours = totalWorkerTime[0] + totalMachineTime[0] + (totalWorkerTime[1] + totalMachineTime[1]) / 60
        let averageHourlyCost = Math.round((partial / totalhours) * 100) / 100

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        let cardExtraStyle = {}

        if (job.urgencyLevel > 0) {
            cardExtraStyle.backgroundColor = '#ffe6a6'
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(job)
            }
        }

        if (type === 'compact') {
            return this.renderCompact()
        }

        //----------------------------------------------------------------------------
        let computedWorkers = this.computeWorkerTimes()
        let computedMachines = this.computeMachineTimes()

        let workerTimeObject
        let machineTimeObject

        const CONVERT_MILLIS = 60 * 60 * 1000
        if (computedWorkers.length !== 0 && computedMachines.length !== 0) {
            let workers = computedWorkers
            let machines = computedMachines

            let workerTimeTotal = 0
            for (let i in workers) {
                if (workers[i].time) {
                    workerTimeTotal += workers[i].time
                } else {
                    workerTimeTotal += (workers[i].workerHours + workers[i].workerMinutes / 60) * CONVERT_MILLIS
                }
            }
            let workerHours = workerTimeTotal / CONVERT_MILLIS
            let workerMinutes = (workerHours % 1) * 60
            workerHours = Math.floor(workerHours)

            let machineTimeTotal = 0
            for (let i in machines) {
                if (machines[i].machineId === 1) continue // skip no_machine
                if (machines[i].time) {
                    machineTimeTotal += machines[i].time
                } else {
                    machineTimeTotal += (machines[i].machineHours + machines[i].machineMinutes / 60) * CONVERT_MILLIS
                }
            }
            let machineHours = machineTimeTotal / CONVERT_MILLIS
            let machineMinutes = (machineHours % 1) * 60
            machineHours = Math.floor(machineHours)

            totalWorkerTime = [workerHours, workerMinutes]
            totalMachineTime = [machineHours, machineMinutes]

            if (totalWorkerTime || totalMachineTime) totalhours = 0

            if (totalWorkerTime) {
                workerTimeObject = timeObject(totalWorkerTime[0], totalWorkerTime[1])
                workerHours = totalWorkerTime[0]
                workerMinutes = totalWorkerTime[1]
                totalhours += workerTimeTotal
            }
            if (totalMachineTime) {
                machineTimeObject = timeObject(totalMachineTime[0], totalMachineTime[1])
                machineHours = totalMachineTime[0]
                machineMinutes = totalMachineTime[1]
                totalhours += machineTimeTotal
            }
        } else {
            if (totalWorkerTime) {
                workerTimeObject = timeObject(totalWorkerTime[0], totalWorkerTime[1])
            }
            if (totalMachineTime) {
                machineTimeObject = timeObject(totalMachineTime[0], totalMachineTime[1])
            }
        }

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        const totalRounded = Math.round(totalBalanceValue * 100) / 100

        totalhours = totalWorkerTime[0] + totalMachineTime[0] + (totalWorkerTime[1] + totalMachineTime[1]) / 60
        averageHourlyCost = Math.round((partial / totalhours) * 100) / 100

        let jobProcessedAliases = []
        if (job && job.aliases) {
            let found = false
            for (const alias of job.aliases) {
                if (alias.alias === job.jobName) {
                    found = true
                }
            }
            if (!found) {
                jobProcessedAliases = [{ alias: job.jobName, jobCode: job.jobCode }, ...job.aliases]
            } else {
                jobProcessedAliases = job.aliases
            }
        }

        return (
            <View className="job-content-container" noflex fullw column between>
                {/* <View fullw column between> */}
                <Box
                    id={job.jobCode}
                    className={selected ? 'rowSelected' : ''}
                    style={{ ...styles.jobBox, ...cardExtraStyle }}
                    {...extraprops}
                    borderWidth="1px"
                    borderRadius="lg"
                    p="4"
                    overflow="hidden"
                >
                    {this.renderExportModal()}
                    {job && (
                        <TaskModal
                            job={job}
                            showModal={showTaskModal}
                            onConfirm={() => {
                                this.setState({ showTaskModal: false })
                            }}
                            onCancel={() => {
                                this.setState({ showTaskModal: false })
                            }}
                        />
                    )}
                    {job && (
                        <DeliveryDateModal
                            job={job}
                            showModal={showDeliveryDateModal}
                            onConfirm={() => {
                                this.setState({ showDeliveryDateModal: false })
                            }}
                            onCancel={() => {
                                this.setState({ showDeliveryDateModal: false })
                            }}
                        />
                    )}
                    <Confirmation
                        message="Vuoi davvero eliminare questa commessa? Tutte le lavorazioni e i costi saranno perduti!"
                        showModal={deleting}
                        onConfirm={() => {
                            this.remove()
                        }}
                        onCancel={() => {
                            this.setState({ deleting: false })
                        }}
                    />
                    <Confirmation
                        message="Vuoi davvero eliminare questa mappatura?"
                        showModal={deletingMapping}
                        onConfirm={() => {
                            this.props.network.deleteMapping({
                                jobCode: job.jobCode,
                            })
                            this.setState({ deletingMapping: false })
                        }}
                        onCancel={() => {
                            this.setState({ deletingMapping: false })
                        }}
                    />
                    <Modal
                        onClose={() => {
                            this.closeModal()
                        }}
                        open={showCostDialog}
                    >
                        <Modal.Header>Aggiunta costo</Modal.Header>
                        <CostEditor
                            sub
                            onChange={(cost) => {
                                //console.log('Creating cost', cost)
                                this.setState({ tempCost: cost })
                            }}
                            job={job.jobCode}
                        />
                        <Modal.Actions>
                            <Button
                                primary
                                onClick={() => {
                                    this.setState({
                                        showCostDialog: false,
                                    })
                                }}
                            >
                                Annulla <Icon name="cancel" />
                            </Button>
                            <Button
                                primary
                                onClick={() => {
                                    const cost = { ...this.state.tempCost }
                                    cost.costDate = moment(this.state.tempCost.costDate).local().format('YYYY-MM-DD HH:mm:ss')

                                    this.props.network.createCost(cost)
                                    const { costs } = this.state
                                    costs.push(cost)

                                    this.setState({
                                        showCostDialog: false,
                                        costs,
                                        tempCost: null,
                                    })
                                }}
                            >
                                Conferma <Icon name="check" />
                            </Button>
                        </Modal.Actions>
                    </Modal>
                    <Box style={styles.jobContent} className="noflexdiv">
                        <Default>{this.renderBoxContent(user, workerTimeObject, machineTimeObject)}</Default>
                        <Mobile>{this.renderBoxContent(user, workerTimeObject, machineTimeObject, 'column')}</Mobile>
                    </Box>
                    {type === 'full' && !noActions && (
                        <Box extra>
                            {!forcedOpen && !external && (
                                <Button
                                    onClick={() => {
                                        if (onOpened) {
                                            onOpened(job, true, () => {
                                                this.props.history.push(route_from(app, jobs, `${job.jobCode.replaceAll('/', '__slash__')}`))
                                            })
                                        } else {
                                            this.props.history.push(route_from(app, jobs, `${job.jobCode.replaceAll('/', '__slash__')}`))
                                        }
                                    }}
                                >
                                    <Icon name="folder open" />
                                    <Default>Apri</Default>
                                </Button>
                            )}
                            {!forcedOpen && !external && false && (
                                <Button
                                    onClick={() => {
                                        if (onOpened) {
                                            onOpened(job, !this.state.showDetails)
                                        } else {
                                            this.setState({ showDetails: !this.state.showDetails })
                                        }
                                    }}
                                >
                                    <Icon name="cogs" />
                                    Dettagli
                                </Button>
                            )}
                            {!external && (
                                <Button
                                    onClick={() => {
                                        // this.props.history.push(route_from(app, jobs, costEdit, `${job.jobCode}`))
                                        this.setState({
                                            showCostDialog: true,
                                        })
                                    }}
                                    color="grey"
                                >
                                    <Icon name="credit card outline" />
                                    Aggiungi costo
                                </Button>
                            )}
                            {/* {!external && user && !!user.manage_job && job && job.jobCode && (
                                <Button
                                    color="green"
                                    onClick={() => {
                                        // Format: YYYY-MM-DD
                                        let momobj = moment(job.lastBillDate)

                                        let data = new Date()
                                        if (momobj.isValid()) {
                                            data = momobj.toDate()
                                        }

                                        this.setState({
                                            editMapping: true,
                                            newmaprow: mappingData.data && mappingData.data[job.jobCode] ? mappingData.data[job.jobCode].row : 0,
                                            newmapcolumn:
                                                mappingData.data && mappingData.data[job.jobCode] ? mappingData.data[job.jobCode].column : 0,
                                        })
                                    }}
                                >
                                    <Icon name="sitemap" />
                                    Imposta posizione stampo
                                </Button>
                            )} */}
                            {external && (
                                <Button
                                    color="green"
                                    onClick={() => {
                                        let { network } = this.props
                                        let { jobCode } = job
                                        network.createJob(jobCode, {
                                            jobCode,
                                            createdExternal: 0,
                                        })
                                    }}
                                >
                                    <Icon name="check square outline" />
                                    Accetta
                                </Button>
                            )}
                            {external && (
                                <Button
                                    color="yellow"
                                    onClick={() => {
                                        let { jobCode } = job
                                        //console.log('Trying to merge', jobCode)
                                        this.props.history.push(route_from(app, tempJobs, jobMerge, `${job.jobCode.replaceAll('/', '__slash__')}`))
                                    }}
                                >
                                    <Icon name="random" />
                                    Unisci a commessa esistente
                                </Button>
                            )}
                            {user && !!user.manage_job && (
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        this.props.history.push(route_from(app, jobs, jobEdit, `${job.jobCode.replaceAll('/', '__slash__')}`))
                                    }}
                                >
                                    <Icon name="edit" />
                                    Modifica
                                </Button>
                            )}
                            {/* {user && !!user.manage_task && (
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        this.setState({
                                            showTaskModal: true,
                                        })
                                    }}
                                >
                                    <Icon name="check" />
                                    Task
                                </Button>
                            )} */}
                            {user && !!user.manage_task && (
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        this.setState({
                                            showDeliveryDateModal: true,
                                        })
                                    }}
                                >
                                    <Icon name="calendar" />
                                    Cambia data di consegna
                                </Button>
                            )}
                            {user && !!user.manage_job && (
                                <Button
                                    color="red"
                                    onClick={() => {
                                        this.openRemoveDialog()
                                    }}
                                >
                                    <Icon name="delete" />
                                    Elimina
                                </Button>
                            )}
                            {forcedOpen && !external && (!role || role === 'admin') && (
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        if (onOpened) {
                                            onOpened(job, true, () => {
                                                this.props.history.push(route_from(app, jobs, job.jobCode.replaceAll('/', '__slash__'), finalBalance))
                                            })
                                        } else {
                                            this.props.history.push(route_from(app, jobs, job.jobCode.replaceAll('/', '__slash__'), finalBalance))
                                        }
                                    }}
                                >
                                    <Icon name="book" />
                                    <Default>Consuntivo</Default>
                                </Button>
                            )}
                            {!external && (!role || role === 'admin') && (
                                <ReactToPrint
                                    trigger={() => {
                                        return (
                                            <Button color="grey">
                                                <Icon name="barcode" />
                                                <Default>Stampa codice a barre</Default>
                                            </Button>
                                        )
                                    }}
                                    content={() => this.componentRef}
                                />
                            )}
                            {false && !external && (!role || role === 'admin') && (
                                <Button
                                    color="blue"
                                    onClick={async () => {
                                        await this.props.network.createCommand({
                                            node: 'ns=2;i=20039',
                                            action: 1,
                                            value: `${job.jobName ? job.jobName : job.jobCode}.ipr`,
                                            endpoint: 'opc.tcp://10.199.115.3:4842',
                                        })

                                        await this.props.network.createCommand({
                                            node: '',
                                            action: 1,
                                            value: `${job.jobName ? job.jobName : job.jobCode}.ipr`,
                                            endpoint: 'down_nb220',
                                        })
                                    }}
                                >
                                    <Icon name="send" />
                                    <Default>Manda a Negribossi 220T</Default>
                                </Button>
                            )}
                            {false && !external && (!role || role === 'admin') && (
                                <Button
                                    color="blue"
                                    onClick={async () => {
                                        await this.props.network.createCommand({
                                            node: 'ns=2;i=20039',
                                            action: 1,
                                            value: `${job.jobName ? job.jobName : job.jobCode}.ipr`,
                                            endpoint: 'opc.tcp://10.203.181.3:4842',
                                        })

                                        await this.props.network.createCommand({
                                            node: '',
                                            action: 1,
                                            value: `${job.jobName ? job.jobName : job.jobCode}.ipr`,
                                            endpoint: 'down_nb130',
                                        })
                                    }}
                                >
                                    <Icon name="send" />
                                    <Default>Manda a Negribossi ELE130</Default>
                                </Button>
                            )}
                            {false && !external && (!role || role === 'admin') && (
                                <Button
                                    color="blue"
                                    onClick={async () => {
                                        await this.props.network.createCommand({
                                            node: '',
                                            action: 1,
                                            value: `${job.jobName ? job.jobName : job.jobCode}.prod`,
                                            endpoint: 'euromap63',
                                        })

                                        await this.props.network.createCommand({
                                            node: '',
                                            action: 1,
                                            value: `${job.jobName ? job.jobName : job.jobCode}.prod`,
                                            endpoint: 'down_wb',
                                        })
                                    }}
                                >
                                    <Icon name="send" />
                                    <Default>Manda a WB Ecopower</Default>
                                </Button>
                            )}
                            {!external && (!role || role === 'admin') && (
                                <MachinePicker
                                    inProduction={false}
                                    onMachineChosen={async (machine) => {
                                        if (machine.machineHadesRef && machine.machineEndpoint) {
                                            if (machine.nodeJob) {
                                                await network.createCommand({
                                                    node: machine.nodeJob,
                                                    action: 1,
                                                    value: `${job.jobCode}`,
                                                    endpoint: machine.machineEndpoint,
                                                })
                                            }

                                            if (machine.nodeCustomer) {
                                                await network.createCommand({
                                                    node: machine.nodeCustomer,
                                                    action: 1,
                                                    value: `${this.state.customerName}`,
                                                    endpoint: machine.machineEndpoint,
                                                })
                                            }

                                            if (machine.nodeArticle) {
                                                await network.createCommand({
                                                    node: machine.nodeArticle,
                                                    action: 1,
                                                    value: `${this.state.workCode}`,
                                                    endpoint: machine.machineEndpoint,
                                                })
                                            }

                                            if (machine.nodeMaterial && this.state.materials && this.state.materials.length > 0) {
                                                await network.createCommand({
                                                    node: machine.nodeMaterial,
                                                    action: 1,
                                                    value: `${this.state.materials[0].materialCode}`,
                                                    endpoint: machine.machineEndpoint,
                                                })
                                            }

                                            if (machine.nodeMold && this.state.molds && this.state.molds.length > 0) {
                                                await network.createCommand({
                                                    node: machine.nodeMold,
                                                    action: 1,
                                                    value: `${this.state.molds[0].moldCode}`,
                                                    endpoint: machine.machineEndpoint,
                                                })

                                                if (machine.nodeMoldDescription) {
                                                    await network.createCommand({
                                                        node: machine.nodeMoldDescription,
                                                        action: 1,
                                                        value: `${this.state.molds[0].moldDescription}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }

                                                if (machine.nodeMoldId) {
                                                    await network.createCommand({
                                                        node: machine.nodeMoldId,
                                                        action: 1,
                                                        value: `${this.state.molds[0].moldCode}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }

                                                if (machine.nodeCavities) {
                                                    await network.createCommand({
                                                        node: machine.nodeCavities,
                                                        action: 1,
                                                        value: `${this.state.molds[0].piecesPerCycle ? this.state.molds[0].piecesPerCycle : 1}`,
                                                        endpoint: machine.machineEndpoint,
                                                    })
                                                }
                                            }

                                            if (machine.nodeQuantity) {
                                                await network.createCommand({
                                                    node: machine.nodeQuantity,
                                                    action: 1,
                                                    value: `${this.state.productQuantity - this.state.producedQuantity}`,
                                                    endpoint: machine.machineEndpoint,
                                                })
                                            }

                                            // await this.props.network.createCommand({
                                            //     node: 'ns=2;i=20039',
                                            //     action: 1,
                                            //     value: `${job.jobCode}.ipr`,
                                            //     endpoint: 'opc.tcp://10.199.115.3:4842',
                                            // })

                                            if (machine.downEndpoint && job.jobProgram) {
                                                await this.props.network.createCommand({
                                                    node: '',
                                                    action: 1,
                                                    value: `${job.jobProgram}`,
                                                    endpoint: `${machine.downEndpoint}`,
                                                })
                                            }
                                        }
                                    }}
                                />
                            )}
                            {/* {user && !!user.manage_job && (
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        this.setState({ exportModal: true })
                                    }}
                                >
                                    <Icon name="clipboard list" />
                                    <Default>Esporta documenti relativi alla commessa</Default>
                                </Button>
                            )} */}
                        </Box>
                    )}
                    {type === 'full' && (forcedOpen || isBalance) && (
                        <Transition visible={forcedOpen || isBalance ? true : this.state.showDetails} animation="slide down" duration={200}>
                            <Box>
                                <View fullw column>
                                    <Box h="8" />

                                    {job.articles && job.articles.length > 0 && (
                                        <>
                                            <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" fontSize="4xl" isTruncated>
                                                Articoli
                                            </Box>

                                            {job.articles.map((value, index) => {
                                                return (
                                                    <Article
                                                        key={`${value.articleId}_${index}`}
                                                        article={{ ...value, description: job.description }}
                                                        workOrderSummary
                                                    />
                                                )
                                            })}
                                        </>
                                    )}

                                    {this.renderControls()}
                                    {this.renderCharts()}
                                    {this.renderDataTable()}
                                    {this.renderMachineTable(machines)}
                                    {this.renderWorkerTable(workers)}
                                    {this.renderCostTable()}
                                    {!isBalance && this.renderWorkTable()}
                                    {/* {this.renderGT2000Table()} */}
                                    {/* {this.renderBabypressaTable()} */}
                                    {isBalance && (
                                        <>
                                            <Items
                                                dateRange={this.props.dateRange}
                                                type="grouped"
                                                onCostChange={(total, shorthand) => this.onCostChange(total, shorthand)}
                                                isBalance
                                                jobId={job.jobCode}
                                                jobAliases={jobProcessedAliases}
                                            />
                                            <View className="no-print" around fullw row style={{ marginBottom: 16 }}>
                                                <Checkbox
                                                    toggle
                                                    defaultChecked={totalWithFixedCosts}
                                                    onChange={(e) => {
                                                        this.setState({ totalWithFixedCosts: e.target.checked }, () => {
                                                            this.onCostChange(0, 0)
                                                        })
                                                    }}
                                                >
                                                    Includi costi fissi nel calcolo totale
                                                </Checkbox>
                                                <Checkbox
                                                    toggle
                                                    defaultChecked={totalWithItems}
                                                    onChange={(e) => {
                                                        this.setState({ totalWithItems: e.target.checked }, () => {
                                                            this.onCostChange(0, 0)
                                                        })
                                                    }}
                                                >
                                                    Includi materiale nel calcolo totale
                                                </Checkbox>
                                            </View>
                                            <View className="only-print" around fullw row style={{ marginBottom: 16 }}>
                                                <span>
                                                    {totalWithFixedCosts ? 'Costi fissi inclusi nel totale' : 'Costi fissi non inclusi nel totale'}
                                                </span>
                                            </View>
                                            <View className="only-print" around fullw row style={{ marginBottom: 16 }}>
                                                <span>{totalWithItems ? 'Materiale incluso nel totale' : 'Materiale non incluso nel totale'}</span>
                                            </View>
                                        </>
                                    )}
                                    {isBalance && (
                                        <Table>
                                            <Table.Header fullWidth>
                                                <Table.Row>
                                                    <Table.HeaderCell style={{ textAlign: 'right' }} width="4">
                                                        Totale ore
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width="1" />
                                                    <Table.HeaderCell style={{ textAlign: 'center' }} width="2">
                                                        Prezzo orario medio
                                                    </Table.HeaderCell>
                                                    {totalWithFixedCosts && <Table.HeaderCell width="1" />}
                                                    {totalWithFixedCosts && (
                                                        <Table.HeaderCell style={{ textAlign: 'center' }} width="2">
                                                            Costi aggiuntivi
                                                        </Table.HeaderCell>
                                                    )}
                                                    {totalWithItems && <Table.HeaderCell width="1" />}
                                                    {totalWithItems && (
                                                        <Table.HeaderCell style={{ textAlign: 'center' }} width="2">
                                                            Costi materiale
                                                        </Table.HeaderCell>
                                                    )}
                                                    <Table.HeaderCell width="1" />
                                                    <Table.HeaderCell width="4">Totale</Table.HeaderCell>
                                                </Table.Row>

                                                <Table.Row>
                                                    <Table.Cell style={{ textAlign: 'right' }}>{floatTimeToString(totalhours)}</Table.Cell>
                                                    <Table.Cell>x</Table.Cell>
                                                    <Table.Cell style={{ textAlign: 'center' }}>
                                                        {isNaN(averageHourlyCost) ? '--' : averageHourlyCost}€/h
                                                    </Table.Cell>
                                                    {totalWithFixedCosts && <Table.Cell>+</Table.Cell>}
                                                    {totalWithFixedCosts && (
                                                        <Table.Cell style={{ textAlign: 'center' }}>
                                                            {!costDict['cost'] || isNaN(costDict['cost']) ? '--' : costDict['cost']}€
                                                        </Table.Cell>
                                                    )}
                                                    {totalWithItems && <Table.Cell>+</Table.Cell>}
                                                    {totalWithItems && (
                                                        <Table.Cell style={{ textAlign: 'center' }}>
                                                            {!costDict['item'] || isNaN(costDict['item'])
                                                                ? '--'
                                                                : Math.round(costDict['item'] * 100) / 100}
                                                            €
                                                        </Table.Cell>
                                                    )}
                                                    <Table.Cell>=</Table.Cell>
                                                    <Table.Cell>{totalRounded}€</Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    )}
                                </View>
                            </Box>
                        </Transition>
                    )}
                </Box>
            </View>
        )
    }
}

const compact_styles = {
    header: {
        fontSize: '20pt',
        lineHeight: 'normal',
    },
    smallHeader: {
        fontSize: '18pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 50,
        height: 50,
    },
    jobImage: {
        width: 50,
        height: 50,
        objectFit: 'contain',
    },
    jobContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    jobBox: {
        width: 200,
        minWidth: 200,
        height: 200,
        margin: 8,
    },
}

const styles = {
    header: {
        fontSize: '40pt',
        lineHeight: 'normal',
    },
    smallHeader: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    imageContainerMobile: {
        width: 100,
        height: 75,
        margin: 'auto',
    },
    jobImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    jobImageMobile: {
        width: 100,
        height: 75,
        objectFit: 'contain',
    },
    jobContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    jobBox: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let {
        getJob,
        login,
        organization,
        getMachineList: {
            data: { data: machineList },
        },
        getWorkerList: {
            data: { data: workerList },
        },
        getMappingData: { data: mappingData, fetching: fetchingMap, status: mapStatus },
        getBabypressaDataFilter,
        getGT2000DataFilter,
        getFromId,
        getFromIdFiltering,
        realtime: { data },
        deleteCost,
    } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return {
        mappingData,
        fetchingMap,
        mapStatus,
        getBabypressaDataFilter,
        getGT2000DataFilter,
        getJob,
        role: rcode,
        machineList,
        workerList,
        getFromId,
        getFromIdFiltering,
        data,
        organization,
        login,
        deleteCost,
    }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Job)))
