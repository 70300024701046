export function createMachine(data) {
    let { createMachineRequest, createMachineError, createMachineResult } = this.props

    delete data.creationDate
    delete data.updateDate

    return new Promise((resolve, reject) => {
        this.doAction(
            this.WEB_REQUEST_URL + 'create_machine',
            this.createBundle(
                'create_machine',
                { machine: data },
                this.POST,
                createMachineRequest,
                (result) => {
                    createMachineResult(result)
                    resolve(result)
                },
                (error) => {
                    createMachineError(error)
                    reject(error)
                }
            )
        )
    })
}
